<template>
  <div id="main-layout" class="container-fluid">
      <AppModals />
      <div class="row content-header">
        <div class="d-none d-lg-block">
          <div @click="setIsLeftAsideVisible(true)" class="button-leftBar" :class="{'closeAside': !toggleLeftVisible}"><i class="fa-solid fa-bars-staggered"></i></div>
          <div v-if="asideEnabled" @click="chatVisible" class="button-rightBar" :class="{'closeAside': !toggleChatVisible}"><i class="fa-solid fa-message-dots"></i></div>
        </div>
        <Header/>
      </div>
      <div class="row content-main">
        <AsideLeft :class="{'hideLeft': !toggleLeftVisible}" class="d-block left-column h-100" @close="setIsLeftAsideVisible" />
        <div class="col center-column d-flex justify-content-between">
          <div class="content row">
            <div class="col-12 px-0 max-vh">
              <transition name="component-fade" mode="out-in">
                <router-view/>
              </transition>
            </div>
          </div>
          <Footer class="content row" />
        </div>
        <AsideRight v-if="asideEnabled" :class="{'hideRight': !toggleChatVisible}" class="d-none d-lg-block right-column h-100"/>
      </div>
    <DisbetFooterMenuMobile :set-is-left-aside-visible="setIsLeftAsideVisible" />
  </div>
</template>

<script>
import {
  Footer,
  Header,
  AppModals
} from '@/common/components'
import AsideRight from '@/modules/asideRight/AsideRight'
import AsideLeft from '@/modules/asideLeft/AsideLeft'
import { mapMutations } from 'vuex'
import DisbetFooterMenuMobile from '@/common/components/FooterMenuMobile/FooterMenuMobile.vue'

export default {
  name: '',
  components: {
    DisbetFooterMenuMobile,
    AppModals,
    Header,
    Footer,
    AsideRight,
    AsideLeft
  },
  props: [''],
  data () {
    return {
      toggleChatVisible: true,
      toggleLeftVisible: window.matchMedia('(min-width: 991px)').matches,
      asideEnabled: false
    }
  },
  methods: {
    ...mapMutations('chat', ['setToggle']),
    chatVisible () {
      this.toggleChatVisible = !this.toggleChatVisible
      this.$emit('toggleChatVisible')
      this.setToggle({ toggleChatVisible: this.toggleChatVisible })
    },
    setIsLeftAsideVisible (isDesktop) {
      if (isDesktop || window.matchMedia('(max-width: 991px)').matches) {
        this.toggleLeftVisible = !this.toggleLeftVisible
        this.$emit('toggleLeftVisible')
        this.setToggle({ toggleLeftVisible: this.toggleLeftVisible })
      }
    }
  }
}
</script>

<style scoped lang="scss">
#main-layout {
  transition: all 0.66s cubic-bezier(0.77, 0.54, 0, 0.85);
  background: linear-gradient(to bottom, #1F242F 0%, #1C1F29 100%);
  box-sizing: border-box;
  min-height: 100%;
}
.component-fade-enter-active {
  animation-name: fadeInDown;
  animation-duration: 0.2s
}

.component-fade-leave-active {
  display: none;
  animation-name: fadeOutDown;
  animation-duration: 0.2s;
}
.content {
  &-main {
    height: calc(100vh - 61px);
    ::-webkit-scrollbar {
      width: 0;
    }
  }
  &-header {
    background-color: #24262B;
  }
}

.center-column {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  display: -webkit-box; /* Safari */
}

.right-column {
  width: 333px;
  transition: .5s !important;
  left: 0;
}

.left-column {
  width: 280px;
  transition: .5s !important;
  left: 0;
}

@media screen and (max-width: 991px) {
  .left-column {
    width: 100%;
  }
}

.max-vh {
  height: 100%;
}

::-webkit-scrollbar {
  width: 0;
}

.button {
  &-leftBar {
    background-color: #2D313C;
    padding: 10px;
    border-radius: 5px;
    width: 45px;
    left: 33px;
    z-index: 5;
    cursor: pointer;
    text-align: center;
    top: 23px;
    position: absolute;
    &:hover {
      background-color: #5E6065;
    }
  }
  &-rightBar {
    background-color: #2D313C;
    padding: 14px;
    border-radius: 5px;
    width: 53px;
    right: 15px;
    cursor: pointer;
    text-align: center;
    top: 19px;
    position: absolute;
    &:hover {
      background-color: #5E6065;
    }
  }
}

.hideRight {
  transform: translateX(120px) !important;
  transition: .3s !important;
  width: 0 !important;
  margin-left: -5px;
}

.hideLeft {
  transform: translateX(-520px) !important;
  transition: .3s !important;
  width: 0 !important;
}

.close {
  font-size: 20px;
}

</style>
