<template>
  <div class="asideLeft-container">
    <div class="close-aside-button" @click="toggle">
      <i class="fa-solid fa-xmark"></i>
    </div>
    <div class="asideLeft-menu d-flex flex-column justify-content-between">
      <PanelMenu :model="items" />
      <div class="d-flex flex-wrap justify-content-center">
        <Button @click="openChatra(); toggle()" class="mb-3 p-button p-component"><i
          class="fa-solid fa-messages"></i> <span
          class="pl-2"></span> Нужна помощь?
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  data () {
    return {
      items: [
        {
          label: 'Казино',
          items: [
            {
              label: 'Слоты',
              icon: 'fa-solid fa-slot-machine',
              to: '/games/casino/main',
              command: this.toggle
            },
            {
              label: 'Live казино',
              icon: 'fa-solid fa-cards',
              to: '/games/live/main',
              command: this.toggle
            },
            {
              label: 'Новые',
              icon: 'fa-solid fa-solid fa-sparkles',
              to: '/games/new/main',
              command: this.toggle
            },
            {
              label: 'Горячие',
              icon: 'fa-solid fa-fire',
              to: '/games/hot/main',
              command: this.toggle
            }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions('app', ['openChatra']),
    toggle () {
      this.$emit('close')
    },
    openVipClub () {
      this.$router.push({ query: { modal: 'vipClub' } })
    }
  }
}

</script>
<style lang="scss" scoped>
.asideLeft {
  &-container {
    height: 100%;
    background-color: #2D313C;
  }
  &-menu {
    margin-top: 50px;
    height: calc(100% - 110px);

    @media screen and (max-width: 991px) {
      height: calc(100% - 170px);
    }
    &-link {
      display: flex;
      color: white;
      padding: 0.5rem;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      transform: translate(0) !important;
    }
  }
}

.asideLeft-container {
  position: relative;
}

.close-aside-button {
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 30px;
  @media (min-width: 991px) {
    display: none;
  }
}

.button {
  &-support {
    min-width: 14rem;
    background-color: #545454;
  }

  &-lang {
    min-width: 14rem;
    margin-bottom: 15px;
  }
}
</style>
