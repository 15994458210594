<template>
  <ProgressSpinner />
</template>

<script>
export default {
  name: 'CommonProgressSpinner'
}
</script>

<style>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #fff700ef;
  }
  40% {
    stroke: #fff700ef;
  }
  66% {
    stroke: #fff700ef;
  }
  80%,
  90% {
    stroke: #fff700ef;
  }
}

</style>
