<template>
  <div class="block active">
    <div class="currency">
      <div v-if="currency">
        <img class="currency__icon" :src="currency.src">
        <span>{{ currency.id }}</span>
      </div>
      <div v-else>
        <span> {{ $t('chat.air_drop.select_currency') }}</span>
      </div>
      <div>
        <i class="pi pi-chevron-right"/>
      </div>
    </div>
    <div class="balance">
      <div>
        <div class="font-weight-normal">{{ $t('cashbox.balance') }}:</div>
        <div class="text-white text-xl">{{ currency ? getBalanceByCurrency(currency.id) : 0 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['currency'],
  computed: {
    ...mapGetters('user', {
      getBalanceByCurrency: 'getBalanceByCurrency'
    })
  }
}
</script>

<style scoped lang="scss">
.block {
  background: #383A44;
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 15px;
  height: 4.5rem;

  .currency {
    margin: -0.5rem 0;
    width: 70%;
    display: flex;
    border-right: 1px solid rgba(196, 196, 196, 0.1);
    justify-content: space-between;
    padding-right: .5rem;

    div {
      margin: auto 0;
    }

    &__icon {
      height: 2rem;
    }

    span {
      color: white;
      margin-left: 1rem;
    }
  }
}

.balance {
  padding-left: 1rem;
  width: 30%;
  margin: -1rem 0;
  color: #90929f;
  display: flex;
  div {
    margin: auto 0;
    @media(max-width: 867px) {
      overflow: hidden;
    }
  }
}

.active {
  border: 1px solid transparent;
  transition: border-color 0.2s;
  border-radius: 15px;
}

.active:hover {
  border-color: #b19df7;
  cursor: pointer;
}

</style>
