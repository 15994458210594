<template>
  <div class="card-telegram">
    <div class="card-body d-flex flex-column">
      <p class="card-body__title">Бонус за подписку на Telegram</p>
      <Button class="p-button p-component card-button">Получить</Button>
    </div>
  </div>
</template>

<script>
export default {
  props: {}
}
</script>

<style lang="scss" scoped>
.card {
  &-telegram {
    border: none;
    color: white;
    border-radius: 25px;
    padding: 25px 25px;
    min-height: 260px;
    overflow: hidden;
    background: rgb(142,67,154);
    background: radial-gradient(circle, rgba(142,67,154,1) 0%, rgba(46,18,125,1) 100%);

    @media (min-width: 1280px) {
      min-height: 260px;
    }
  }

  &-button {
    border-radius: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  &-body {
    &__title {
      font-size: 22px;
      font-weight: bold;
      margin: 0;
      line-height: 30px;

      @media (max-width: 767px) {
        font-size: 22px;
      }

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }
  }
}
</style>
