import { apiTransport } from './transport'
import { DISBET_REGISTRATION_URL } from '../../constants'

class RegistrationApiService {
  constructor (transport) {
    this.transport = transport
  }

  registerFast ({ refHash, rToken, nick, avatar, currency }) {
    return this.transport.post(`${DISBET_REGISTRATION_URL}/fast`, { refHash, rToken, nick, avatar, currency }).then(data => {
      this.transport.setAuthenticationToken(data)
      return data
    })
  }

  registerEmail ({ refHash, email, password, rToken, avatar, nick, login, currency }) {
    return this.transport.post(`${DISBET_REGISTRATION_URL}/email`, { refHash, email, password, rToken, avatar, nick, login, currency }).then(data => {
      this.transport.setAuthenticationToken(data)
    })
  }
  sendEmail (email, password, login) {
    return this.transport.post(`${DISBET_REGISTRATION_URL}/emailSend`, { email, password, login })
  }
}

export const registrationApiService = new RegistrationApiService(apiTransport)
