<template>
  <div class="header">
    <div class="header-container">
      <div class="left">
        <router-link tag="div" to="/main" class="header-logo">
          <LogoIcon class="svg-icon"/>
        </router-link>
      </div>
      <div class="left-search">
        <div @click="toggleOPFind" class="find">
          <span class="p-input-icon-left">
            <i class="pi pi-search"/>
            <InputText class="find-input" type="text" :placeholder="$t('header.search')" v-model="searchValue"/>
          </span>
        </div>
        <OverlayPanel
          :baseZIndex="-50"
          class="tooltip__currencies"
          appendTo="body" ref="op2" :showCloseIcon="false"
          style="max-width: 1150px; background: rgb(31, 33, 41); border-radius: 5px; margin-top: 15px">
          <GameFind :keyword="searchValue" @close="toggleOPFind"/>
        </OverlayPanel>
      </div>
      <div class="right">
        <DisbetLang v-if="!auth" />
        <div v-if="!auth" class="hr"/>
        <div v-if="auth" @click="toggleOPChangeAccount" class="balance-wrapper ml-0">
          <div class="d-flex align-items-center">
            <img alt="logo" class="currency-icon" :src="currentAccount.currency.src"/>
            <span class="ml-1"> {{ currentAccount.currency.id }} <i
              class="fas fa-chevron-down"></i></span>
          </div>
          <div>
            <span class="d-block balance text-white">
              <number :to="currentAccount.balance" :from="currentAccount.balance" :format="(v) => theFormat(currentAccount, v)"/>
            </span>
          </div>
        </div>
        <OverlayPanel
          :baseZIndex="-50"
          class="tooltip__currencies"
          appendTo="body" ref="op" :showCloseIcon="false" style="width: 370px">
          <AccountChooser :onChooseAccount="onChangeAccountCurrency"/>
        </OverlayPanel>
        <Button v-show="!auth" @click="$router.push({ query: { modal: 'auth' } })" class="button-submit">
          {{ $t('header.auth') }}
        </Button>
        <Button v-if="auth" @click="$router.push({ query: { modal: 'cash' } })" class="button-submit">
          <span><i class="fas fa-wallet"></i> {{ $t('header.cashbox') }}</span>
        </Button>
        <div @click="toggleOPProfile" v-if="auth" class="profile">
          <div class="profile-container">
            <DisbetCustomAvatar class="avatar" :src="user.avatar"
                                :username="user.nick" :size="40"></DisbetCustomAvatar>
            <span class="profile-icon">
                <i class="fas fa-list-ul">
                   <OverlayPanel
                     :baseZIndex="-20"
                     class="tooltip__menu"
                     appendTo="body" ref="op1" :showCloseIcon="false" style="width: 370px">
                    <MenuTooltipProfile @close="toggleOPProfile()"
                    />
                  </OverlayPanel>
                </i>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import MenuTooltipProfile from './components/MenuTooltipProfile'

import LogoIcon from '@/assets/svg/logo-icon.svg'
import { DisbetCustomAvatar } from '@/common/components'
import AccountChooser from '@/common/components/AccountChooser/AccountChooser'
import { DisbetLang } from '@/common/components/Lang'
import GameFind from '@/common/components/GameFind/GameFind'

export default {
  components: {
    GameFind,
    DisbetCustomAvatar,
    MenuTooltipProfile,
    LogoIcon,
    AccountChooser,
    DisbetLang
  },
  props: {
    text: {
      type: String,
      default: 'Dropdown'
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    naked: {
      type: Boolean,
      default: false
    }
  },
  provide () {
    return {
      dropdown: this
    }
  },
  data () {
    return {
      searchValue: ''
    }
  },
  computed: {
    ...mapGetters('navigations', ['pages']),
    ...mapGetters('currency', ['getCurrencyCourseToDollar']),
    ...mapGetters('user', ['auth', 'currentAccount', 'user', 'accounts', 'getBalanceByCurrency', 'showBalanceInUsd'])
  },
  methods: {
    theFormat (currentAccount, number) {
      if (this.showBalanceInUsd) {
        return `$ ${this.getCurrencyCourseToDollar(currentAccount.currency.id, number)}`
      }
      return `${Number(number).toFixed(currentAccount.currency.precisionUnit)}`
      // return `${Number(number).toFixed(8)}`
    },
    panelClose () {
      this.$refs.op1.hide()
    },
    ...mapActions('user', ['changeAccount']),
    toggleOPChangeAccount (e) {
      this.$refs.op.toggle(e)
    },
    toggleOPProfile (e) {
      this.$refs.op1.toggle(e)
    },
    toggleOPFind (e) {
      this.$refs.op2.toggle(e)
    },
    onChangeAccountCurrency (account) {
      this.changeAccount({ accountId: account.id })
      this.$refs.op.hide()
    }
  }
}
</script>
<style scoped lang="scss">
.p-overlaypanel:after, .p-overlaypanel:before {
  left: calc(var(--overlayArrowLeft, 0) + 1.85rem);
}

.p-overlaypanel:after, .p-overlaypanel:before {
  display: none;
}

.p-overlaypanel {
  margin: 0 auto;
}

.find {
  border-radius: 10px;
  &-input {
    border-radius: 15px;
    height: 1rem;
    min-width: 22rem;
    background: #15181F;
    @media (max-width: 1277px) {
      min-width: 0rem;
      width: 226px;
    }
  }
  span {
    width: 100%;
  }
}

.profile {
  height: 100%;
  padding: 2px;
  &-container {
    display: flex;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    margin-left: 15px;
    .avatar {
      margin-right: -25px;
      margin-top: 2px;
    }
  }
  &-icon {
    padding: 12px;
    padding-left: 30px;
    border-radius: 15px;
    background: #292E3D;
    width: 60px;
  }
  &:hover {
    transition: all .2s ease;
    color: #fff;
  }
}

.balance {
  color: rgb(255, 193, 6);
  font-weight: 300;
  margin-left: 27px;
  margin-top: -4px;
  &-wrapper {
    background-color: #292E3D;
    cursor: pointer;
    border-radius: 25px 0 0 25px;
    padding: 5px 0 0 20px;
    margin-right: -30px;
    min-width: 166px;
    @media (max-width: 500px) {
      min-width: 140px;
    }
    .currency-icon {
      width: 18px;
    }
  }
}

.tooltip {
  &__currencies {
    background-color: #0F1114;
    border-radius: 15px;
    padding: 10px;
    text-align: center;
    z-index: 10;
  }

  &__menu {
    background-color: #0F1114;
    border-radius: 15px;
    text-align: center;
    z-index: 10;
  }

  &__currencies-body--item {
    font-size: 15px;
    padding: 10px;
    border-radius: 3px;
    text-align: left;
    color: rgba(0, 0, 0, .75);

    &:hover {
      background-color: #d0d0d0;
    }
  }

  &__currencies-body--item-name {
    margin-right: 6px;
    font-weight: 600;
  }
}

.header {
  width: 100%;
  background-color: #1C1F29;
  &-container {
    display: flex;
    .left {
      width: 120px;
      margin-left: 90px;
      height: 100%;
      display: flex;
      max-width: 150px;
      background-color: transparent;
      @media (max-width: 430px) {
        display: none
      }
      @media screen and (max-width: 992px) {
        height: 84px;
        max-width: 111px;
        margin-left: 0;
      }
      @media screen and (min-width: 992px) {
        max-width: 100%;
      }
      &-search {
        padding: 23px;
        @media screen and (max-width: 1103px) {
          display: none;
        }
      }
    }

    .right {
      margin-left: auto;
      padding: 20px;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 430px) {
        width: 100%;
        justify-content: space-between;
      }
      @media screen and (max-width: 991px) {
        margin-right: 0;
      }
      &-menu {
        display: flex;
        align-items: center;
      }
      .hr {
        border-left: 1px solid #c4c4c436;
        height: 100%;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  &-logo {
    width: 180px;
    padding: 20px;
    margin: auto;
    @media screen and (max-width: 992px) {
      padding: 20px;
      margin: 0;
    }
    @media (max-width: 430px) {
      width: 100%;
    }
    .svg {
      cursor: pointer;
    }
    .svg-icon {
      cursor: pointer;
    }
  }
}

.button {
  &-submit {
    height: 100%;
    min-height: 49px;
  }
}

.fa-chevron-down {
  font-size: 10px;
  margin-left: 5px;
  margin-top: 2px;
}

.fa-coin {
  color: #65881A;
}

.fa-wallet {
  @media screen and (max-width: 992px) {
    display: none;
  }
}
</style>
