<template>
  <div class="container-xl">
    <div class="bonus">
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-column">
          <div class="mt-4">
            <BonusCard/>
          </div>
          <div class="block">
            <div class="telegram">
              <Telegram/>
            </div>
            <div class="promocode">
              <PromoCode/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="block d-flex">
          <div class="fortune">
            <Fortuna/>
          </div>
          <div class="cashback">
            <Cashback/>
          </div>
        </div>
      </div>
    </div>
    <div class="">
        <Rakeback />
    </div>
  </div>
</template>

<script>
import BonusCard from './components/Cards/BonusCard.vue'
import Telegram from './components/Cards/Telegram.vue'
import PromoCode from './components/Cards/PromoCode.vue'
import Fortuna from './components/Cards/Fortuna.vue'
import Cashback from './components/Cards/Cashback.vue'
import Rakeback from './components/Cards/Rakeback.vue'

export default {
  components: {
    BonusCard,
    Telegram,
    PromoCode,
    Fortuna,
    Cashback,
    Rakeback
  }
}
</script>

<style lang="scss" scoped>
.bonus {
  display: flex;
  @media (max-width: 991px) {
    display: block;
  }
}
.block {
  justify-content: center;
  display: flex;
  height: 100%;
  @media (max-width: 580px) {
    display: block;
  }
  .promocode {
    width: 48%;
    margin-top: 25px;
    margin-left: 1.5%;
    @media (max-width: 580px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .telegram {
    width: 48%;
    margin-right: 1.5%;
    margin-top: 25px;
    @media (max-width: 580px) {
      width: 100%;
      margin-right: 0;
    }
  }
  .fortune {
    width: 48%;
    margin-right: 3.5%;
    margin-top: 25px;
  }
  .cashback {
    width: 48%;
    margin-top: 25px;
  }
}
</style>
