<template>
  <div class="d-flex flex-column flex-md-row flex-wrap">
    <div v-for="item in levelContent" :key="item.title" class="card mt-3 mb-3 mr-4">
      <img class="card__image" :src="require(`@/assets/images/vip-club/ru/permissions/${item.key}.png`)" :alt="item.key" />
      <p class="card__title m-0">{{ item.title }}</p>
      <p v-if="item.description" class="card__description">{{ item.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    level: {
      type: Number,
      required: true
    }
  },
  computed: {
    levelContent () {
      const contentMapper = {
        1: [
          { title: 'Рейкбек 1%', key: 'rakeback' },
          { title: 'Колесо фортуна до 100.00', key: 'fortune' }
        ],
        2: [
          { title: 'Колесо фортуна до 100.00', key: 'fortune' },
          { title: 'Награда за уровень до 10.00', key: 'level' }
        ],
        3: [
          { title: 'Колесо фортуна до 250.00', key: 'fortune' }
        ],
        4: [
          { title: 'Награда за уровень до 50.00', key: 'level' },
          { title: 'Колесо фортуна до 500.00', key: 'fortune' }
        ],
        5: [
          { title: 'Рейкбек 1%', key: 'rakeback' }
        ],
        6: [
          { title: 'Колесо фортуна до 1000.00', key: 'fortune' },
          { title: 'Награда за уровень до 250.00', key: 'level' }
        ],
        7: [
          { title: 'Колесо фортуна до 1250.00', key: 'fortune' }
        ],
        8: [
          { title: 'Рейкбек 1%', key: 'rakeback' },
          { title: 'Колесо фортуна до 1000.00', key: 'fortune' },
          { title: 'Награда за уровень 250.00', key: 'level' }
        ],
        9: [
          { title: 'Колесо фортуна до 2000.00', key: 'fortune' }
        ],
        10: [
          { title: 'Колесо фортуна до 500.00', key: 'fortune' },
          { title: 'Награда за уровень до 50.00', key: 'level' },
          { title: 'Вывод без комиссии', key: 'comission' }
        ],
        11: [
          { title: 'Твой VIP помощник', key: 'vip', description: 'Поможет в любое время' }
        ],
        12: [
          { title: 'Колесо фортуна до 5000.00', key: 'fortune' },
          { title: 'Награда за уровень до 5000.00', key: 'level' }
        ],
        13: [
          { title: 'Колесо фортуна до 10.000.00', key: 'fortune' },
          { title: 'Награда за уровень до 10.000.00', key: 'level' }
        ],
        14: [
          { title: 'Колесо фортуна до 20.000.00', key: 'fortune' },
          { title: 'Награда за уровень до 20.000.00', key: 'level' }
        ],
        15: [
          { title: 'Колесо фортуна до 50.000.00', key: 'fortune' },
          { title: 'Награда за уровень до 50.000.00', key: 'level' }
        ]
      }
      return contentMapper[this.level] || []
    }
  }
}
</script>

<style scoped lang="scss">
  .card {
    padding: 15px 20px;
    width: 220px;
    height: 130px;
    position: relative;
    background-image: url(../../../../assets/images/vip-club/ru/permissions/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    user-select: none;
    border: 1px solid white;
    overflow: hidden;

    @media (max-width: 768px) {
      width: 100%;
    }

    &__description {
      margin-top: 6px;
      max-width: 70px;
      font-size: 10px;
    }

    &__image {
      width: 120px;
      height: 90px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__title {
      font-size: 16px;
    }
  }
</style>
