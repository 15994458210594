<template>
  <div>
    <button @click="BetMin()"
            class="col-2 fz-08 btn btn-outline-dark btn-block px-0">MIN
    </button>
    <button @click="x2BetM()"
            class="col-2 fz-08 btn btn-outline-dark btn-block px-0">X2
    </button>
    <input type="number" class="form-control col-4" :value="this.localValue" @keyup="this.changeValue"/>
    <button @click="x2BetD()"
            class="col-2 fz-08 btn btn-outline-dark btn-block px-0">X/2
    </button>
    <button @click="BetMax()"
            class="col-2 fz-08 btn btn-outline-dark btn-block px-0">MAX
    </button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    onSetValue: {
      default: () => {},
      type: Function
    },
    minValue: {
      default: 1,
      type: Number
    },
    maxValue: {
      default: 1000,
      type: Number
    },
    defaultValue: {
      default: 1,
      type: Number
    }
  },
  data () {
    return {
      localValue: 1
    }
  },
  computed: {
    ...mapGetters('user', {
      user: 'user',
      checkAuth: 'auth',
      getBalance: 'balance'
    })
  },
  methods: {
    ...mapActions('user', {
      setBalance: 'balance'
    }),
    changeValue (e) {
      const value = e.target.value ? e.target.value : 0
      this.updateValue(value)
    },
    x2BetM () {
      this.updateValue(this.localValue * 2)
    },
    x2BetD () {
      this.updateValue(this.localValue / 2)
    },
    BetMin () {
      this.updateValue(0)
    },
    BetMax () {
      let balance = this.getBalance / 100
      this.updateValue(balance >= this.maxValue ? this.maxValue : balance)
    },
    updateValue (value) {
      value = parseInt(value)
      const maxValue = this.maxValue
      const minValue = this.minValue

      let setValue = minValue
      if (maxValue < value) {
        setValue = maxValue
      } else if (minValue > value) {
        setValue = minValue
      } else {
        setValue = value
      }
      this.localValue = setValue
      this.onSetValue(setValue)
    }
  },
  created: function () {
    this.localValue = this.defaultValue
  }
}

</script>

<style scoped lang="scss">

  :focus {
    outline: none;
  }

  input[type="reset"]::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  input[type="number"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  input[type="file"] > input[type="button"]::-moz-focus-inner { /* */
    border: none;
    outline: none;

  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 0;
  }

  ::-webkit-scrollbar-button {
    background-color: #78787800;
    display: none;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #78787800;
  }

  ::-webkit-scrollbar-thumb {
    height: 1px;
    background-color: #ffc107;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    background-color: #78787800;
  }

  ::-webkit-resizer {
    background-color: #666;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .btn-outline-dark:disabled {
    color: #828a93;
    background-color: rgba(23, 25, 29, 0.52);
    border: 0;
  }

  .btn-outline-dark {
    color: white;
    height: 100%;
    background-color: #585E6A;
    border: 0;
    border-radius: 0;
    margin: 0 !important;

  &:first-child {
     border-top-left-radius: 8px;
     border-bottom-left-radius: 8px;
   }

  &:last-child {
     border-top-right-radius: 8px;
     border-bottom-right-radius: 8px;
   }
  }

  .form-control {
    color: white;
    text-align: center;
    height: 100%;
    border-radius: 0;
    background-color: #121318;
    border: 0 solid #383838;
    width: 96%;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .form-control::-webkit-input-placeholder {
    color: #495057;
  }

  .form-control::-moz-placeholder {
    color: #495057;
  }

  .form-control:-moz-placeholder {
    color: #495057;
  }

  .form-control:-ms-input-placeholder {
    color: #495057;
  }

  .form-control:focus {
    text-align: center;
    border-radius: 0;
    background-color: #15171d;
    border: 0 solid #383838;
    box-shadow: none;
    color: #90929f;
  }
</style>
