export const LEVELS_MAP = [
  { value: 1, scores: 0 },
  { value: 2, scores: 10 },
  { value: 3, scores: 100 },
  { value: 4, scores: 500 },
  { value: 5, scores: 1000 },
  { value: 6, scores: 2500 },
  { value: 7, scores: 5000 },
  { value: 8, scores: 10000 },
  { value: 9, scores: 20000 },
  { value: 10, scores: 50000 },
  { value: 11, scores: 100000 },
  { value: 12, scores: 150000 },
  { value: 13, scores: 250000 },
  { value: 14, scores: 500000 },
  { value: 15, scores: 500000 }
]
