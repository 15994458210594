<template>
  <div class="helpdesk">
    <div class="helpdesk-header">
      <i @click="$router.go(-1)" class="pi pi-chevron-left"></i>
      <h4>
        {{ $t('footer.helpdesk') }}
      </h4>
    </div>
    <div class="helpdesk-container">
      <div class="helpdesk-menu">
        <div :key="idx" v-for="(helpdesk, idx) in menu">
          <router-link active-class="active" class="button" tag="a" :to="helpdesk.link">{{ helpdesk.name }}</router-link>
        </div>
      </div>
      <div class="helpdesk-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menu: [
        {
          name: 'FAQ',
          link: '/helpdesk/faq'
        },
        {
          name: 'Политика кондифициальности',
          link: '/helpdesk/policy'
        },
        {
          name: 'Пользовательское соглашение',
          link: '/helpdesk/terms'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.helpdesk {
  &-header {
    width: 100%;
    display: flex;
    padding: 25px;
    cursor: pointer;
    font-weight: 700;
    font-size: 24px;
    align-items: center;
    h4 {
      color: white;
      font-weight: bold;
      margin-top: 7px;
      margin-left: 10px;
    }
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  &-content {
    border-radius: 5px;
    padding: 0 0 0 25px;
    width: 64%;
    @media (max-width: 1636px) {
      min-width: 100%;
      margin-top: 25px;
      border-radius: 0;
    }
  }
  &-menu {
    background-color: rgb(38, 42, 54);
    border-radius: 15px;
    width: 350px;
    @media (max-width: 1636px) {
      width: 100%;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .button {
      font-size: 18px;
      cursor: pointer;
      width: 350px;
      height: 60px;
      display: flex;
      align-items: center;
      color: #969696;
      background-color: #262A36;
      padding: 0 0 0 20px;
      @media (max-width: 1636px) {
        width: 100%;
      }
    }
    .active {
      background-color: #1B1E26;
      color: white;
    }
  }
}
</style>
