<template>
  <div class="user-modal">
    <div class="user-modal__container">
      <DisbetPreloader v-if="fetchStatus === 'FETCH'"/>
      <div class="m-3 text-center" v-else-if="fetchStatus === 'ERROR'">
        {{ $t('chat.error_load_data') }}
      </div>
      <div v-else class="user-modal__scroll">
        <div class="row">
          <div class="col-12 justify-content-center">
            <div class="mb-2 pt-3 user-modal__body--user-info">
              <DisbetCustomAvatar style="width: 91px" :src="info.avatar" :username="info.nick"
                                  :size="87"></DisbetCustomAvatar>
              <div class="mt-2">
                {{ info.nick }}
              </div>
            </div>
            <div @click="changeUserStatisticPage({ pageUserStatistic: USER_STATISTIC_PAGES.USER_EDIT_NAME })"
                 v-if="info.nick === nick" class="p-button"><i class="fa fa-pen"></i>
            </div>
          </div>
        </div>
        <div class="user-modal__body--container">
          <div  class="user-modal__body--info">
            <div class="user-modal__body--header col-12">
              <i class="icon-color fas fa-chart-bar"></i> {{ $t('chat.statistic') }}
            </div>
            <div class="col-sm-6 col-12 pr-sm-1">
              <div class="mb-2 user-modal__body--block">
                <div class="name">{{ $t('user_modal.bet_amount') }}</div>
                <div class="bet-amount">
                  {{ (statistic.betsAmount) }}
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12 pl-sm-1">
              <div class="mb-2 user-modal__body--block">
                <div class="name">{{ $t('user_modal.total_games') }}</div>
                <div class="total-game">
                  {{ statistic.gamesPlayed }}
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12 pr-sm-1">
              <div class="mb-2 user-modal__body--block">
                <div class="name">{{ $t('user_modal.best_multiplier') }}</div>
                <div class="best-multiplier">
                  {{ statistic.betterRate }}<i class="pi pi-times"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12 pl-sm-1">
              <div class="mb-2 user-modal__body--block">
                <div class="name">{{ $t('user_modal.best_winning') }}</div>
                <div class="best-winning">
                  {{ statistic.betterWin }}
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-modal__body--container">
          <div class="user-modal__body--info">
            <div class="user-modal__body--header col-12">{{ $t('chat.favorite_games') }}</div>
            <div class="m-auto" v-if="statistic.gamesPlayed < 1">
              <div class="row m-auto justify-content-center mt-4">
                <p class="col-12 text-center">{{ $t('main.table_data_null') }}</p>
              </div>
            </div>
            <div :key="game.id" v-for="(game) of statistic.lovesGames" class="favourite-games__body">
              <div class="favourite-games__img">
                <img class="img-favorite" :src=url(game.id) alt="img">
              </div>
              <div class="favourite-games__content">
                <div class="favourite-games__header">{{ game.title }}</div>
                <div class="favourite-games__description">{{ $t('chat.try_luck') }}
                </div>
              </div>
              <div class="favourite-games__total-win">
                <div class="favourite-games__total-win__text">{{ $t('chat.bet') }}</div>
                <div class="favourite-games__total-win__sum">{{ game.winInGame }}$</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utils from '@/mixins/utils'

import {
  DisbetCustomAvatar,
  DisbetPreloader
} from '@/common/components'
import { toDateString } from '@/utils/date'
import { USER_STATISTIC_PAGES } from '@/modules/userStatistic/constants'

export default {
  name: 'UserInfo',
  mixins: [utils],
  data () {
    return {
      USER_STATISTIC_PAGES
    }
  },
  components: {
    DisbetCustomAvatar,
    DisbetPreloader
  },
  computed: {
    ...mapGetters('userStatistic', { fetchStatus: 'getFetchStatus', statistic: 'getStatistic', info: 'getInfo' }),
    ...mapGetters('user', { nick: 'nick' })
  },
  methods: {
    joinedDateTime (startDate) {
      return toDateString(new Date(startDate))
    },
    ...mapActions('userStatistic', ['changeUserStatisticPage', 'initUserStatistic'])
  }
}
</script>

<style lang="scss" scoped>
.v--modal {
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.v--modal-overlay {
  background: rgba(0, 0, 0, 0.76);
}

.v--modal-box {
  top: 0 !important;
}

.user-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(90vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
  max-height: 523px;

  &__scroll {
    height: 500px;
  }

  &__header {
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: bold;
    flex: 1 1 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(245, 246, 247);
  }

  &__container {
    position: relative;
    background-color: #1c1f29 !important;
    color: white;
    border: none;
    width: 465px;
    border-radius: 15px;
    height: 100%;
  }

  &__body--user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__body {
    &--block {
      height: 70px;
      background-color: rgba(0, 0, 0, 0.34);;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 4px;
      border-radius: 15px;
    }

    &--bet {
      font-size: 12px;
      text-align: right;
      color: #9093a1;
    }

    &--header {
      font-weight: 700;
      margin-bottom: 10px;
      color: #fff;

      i {
        margin-right: 5px;
      }
    }

    &--info {
      background-color: #292C38;
      padding: 15px;
      border-radius: 15px;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    &--join {
      color: #90929f;
      font-size: 14px;
    }

    &--container {
      margin-top: 20px;
    }
  }

  &__close {
    border-radius: 6px;
    position: absolute;
    top: 2%;
    right: 1%;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: flex-end;

    &-icon {
      padding: 10px;
      font-size: 22px;
      color: #90929f;
      margin-top: -2px;

      &:hover {
        color: #6e7078;
      }
    }
  }
}

.user-modal::-webkit-scrollbar {
  width: 0;
}

.bet-amount {
  color: white;
  font-size: 20px;
  font-weight: 700;
}

.total-game {
  color: white;
  font-size: 20px;
  font-weight: 700;
}

.best-multiplier {
  color: white;
  font-size: 20px;
  font-weight: 700;
}

.img-favorite {
  border-radius: 10px;
}

.best-winning {
  color: white;
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 576px) {
  .user-modal {
    background: #1c1f29;
    align-items: flex-start;
    width: calc(100vw - 33px);
    max-height: 100vh;
    height: calc(100vh - 55px);

    &__body {
      &--user-info {
      }
    }

    &__container {
      width: 100%;
    }

    &__scroll {
      overflow-y: inherit;
      overflow-x: inherit;
    }

    &__header {
      padding: 20px;
      position: fixed;
      z-index: 100;
      width: 100%;
      background: #1c1f29;
    }

    &__close {
      position: fixed;
      top: 1.9%;
      right: 1.8%;
    }
  }
}

.name {
  color: #90929f;
  margin-top: 7px;
  font-size: 12px;
  font-weight: 700;
}

.icon-color {
  color: #9093a1;
}

.text-orange {
  color: #ffbb22;
}

.button-send {
  font-size: 12px;
  height: 22px;
  display: flex;
}

.favourite-games {
  &__body {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__img {
    margin: 5px 10px 0 0;

    img {
      display: block;
      width: 60px;
      height: 50px;
    }
  }

  &__content {
    padding-right: 30px;
  }

  &__header {
    font-size: 12px;
  }

  &__description {
    font-size: 10px;
    color: #969696;
    line-height: 12px;
  }

  &__total-win {
    font-size: 10px;
    text-align: center;
    width: 135px;
    position: relative;
    min-width: 80px;
    &__text {
    }

    &__sum {
    }
  }

  &__total-win::before {
    content: "";
    position: absolute;
    left: -23px;
    top: -12px;
    height: 55px;
    width: 2px;
    background-color: rgba(196, 196, 196, 0.6);
  }
}

.fa-pen {
  color: #969696;
  font-size: 16px;
  transition: all .3s ease-in-out;
}

.p-button {
  background-color: rgba(57, 63, 83, 0.43);
  border-radius: 30px;
  padding: 11px;
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
}

.p-button:hover .fa-pen {
  color: #fff;
}

</style>
