<template>
  <DisbetModal class="content">
    <ModalHeader/>
    <button @click="$emit('close')" class="dm-close-icon">
      <i style="font-size: 1.15rem;" class="pi pi-times"></i>
    </button>
    <ModalBody :payment="payment"/>
  </DisbetModal>
</template>
<script>
import { DisbetModal } from '@/common/components'

import ModalBody from './Body.vue'
import ModalHeader from './Header.vue'

export default {
  props: ['payment'],
  components: { ModalHeader, ModalBody, DisbetModal },
  methods: {},
  created () {}
}
</script>
<style lang="scss" scoped>
.dm-close-icon {
  position: absolute;
  font-weight: bold;
  right: 10px;
  top: 17px;
  z-index: 11;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: transparent;
  color: #969696;
}

.content {
  padding: 1rem;
  min-height: 667px;
  @media (max-width: 700px) {
    min-height: 100%;
  }
}

img {
  height: 25px;
  margin-left: 5px;
}
</style>
