import Bonus from '@/pages/Bonus/Bonus'

export const bonusRouter = [
  {
    name: 'Disapp - Bonus',
    meta: { auth: true },
    path: '/bonus/',
    component: Bonus
  }
]
