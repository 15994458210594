export const modalsNames = {
  DAC: 'DAC',
  DEPOSIT_BONUS_LIST: 'DEPOSIT_BONUS_LIST',
  DEPOSIT_BONUS_INFO: 'DEPOSIT_BONUS_INFO'
}

export const bonusTypes = {
  REGISTER: 0,
  DEPOSIT: 1,
  PROMO_CODE: 2,
  SOCIAL: 3,
  REFERRAL: 4,
  AIRDROP: 5,
  FIRST_DEPOSIT: 6,
  SECOND_DEPOSIT: 7,
  THIRD_DEPOSIT: 8
}
