import { casinoApiService } from '@/common/services/apiServices'

export default {
  namespaced: true,
  state: {
    games: [],
    gamesStatistic: [],
    categories: [],
    providers: [],
    producers: [],
    currentGameOpened: {},
    currentGame: {},
    historyGames: [],
    historyGamesGlobal: [],
    userHistoryGames: [],
    bigWins: [],
    coolMoments: [],
    searchGames: [],
    gamesByLists: []
  },
  getters: {
    currentGameOpened: state => state.currentGameOpened,
    providers: state => state.providers,
    producers: state => state.producers,
    getProvidersById: state => (id) => {
      return state.providers.filter(provider => provider.id === id)
    },
    getProducersByName: state => (name) => {
      return state.producers.find(producer => producer.id === name)
    },
    searchGames: state => state.searchGames.games,
    searchCount: state => state.searchGames.count,
    gamesStatistic: state => state.gamesStatistic,
    currentGame: state => state.currentGame,
    historyGames: state => state.historyGames,
    historyGamesGlobal: state => state.historyGamesGlobal,
    bigWins: state => state.bigWins,
    coolMoments: state => state.coolMoments,
    userHistoryGames: state => state.userHistoryGames,
    getListByName: (state) => value => {
      return state.gamesByLists.find(item => item.id === value)
    }
  },
  mutations: {
    socket_GAME (state, [event, data]) {
      let userHistoryGames = state.userHistoryGames.slice(0, 9)
      console.log(this.getters['user/id'])
      if (data.user.id === this.getters['user/id']) {
        userHistoryGames.unshift(data)
      }
      console.log(userHistoryGames)
      state.userHistoryGames = userHistoryGames

      let historyGamesGlobal = state.historyGamesGlobal.slice(0, 9)
      historyGamesGlobal.unshift(data)
      state.historyGamesGlobal = historyGamesGlobal

      let bigWins = state.bigWins.slice(0, 9)
      if (data.log.totalWin / 100 > 1000) {
        bigWins.unshift(data)
      }
      state.bigWins = bigWins

      let coolMoments = state.coolMoments.slice(0, 9)
      if (data.log.rate > 10) {
        coolMoments.unshift(data)
      }
      state.coolMoments = coolMoments
    },
    setCategories (state, data) {
      state.categories = data
    },
    setSearchGames (state, data) {
      state.searchGames = data
    },
    setProviders (state, data) {
      state.providers = data
    },
    setProducers (state, data) {
      state.producers = data
    },
    setgamesStatistic (state, data) {
      state.gamesStatistic = data
    },
    setCurrentGame (state, data) {
      state.currentGame = data
    },
    setGamesByLists (state, data) {
      state.gamesByLists = data
    },
    setSelectedCategory (state, data) {
      state.selectedCategory = data
    },
    setSelectedProducer (state, data) {
      state.selectedProducer = data
    }
  },
  actions: {
    openGame (store, { gameId, currency }) {
      return casinoApiService.openGame(gameId, currency).then(data => {
        store.commit('setCurrentGame', data)
        return data
      })
    },
    getCategories (store) {
      return casinoApiService.getCategories().then(data => {
        store.commit('setCategories', data)
        return data
      })
    },
    getGamesStatistic (store) {
      return casinoApiService.getCategories().then(data => {
        store.commit('setCategories', data)
        return data
      })
    },
    getProviders (store, { type = null }) {
      return casinoApiService.getProviders({ type }).then(data => {
        store.commit('setProviders', data)
        return data
      })
    },
    getProducers (store, { type = null }) {
      return casinoApiService.getProducers({ type }).then(data => {
        store.commit('setProducers', data)
        return data
      })
    },
    getGames (store, { providerId = null, categoryId = null, producerId = null }) {
      return casinoApiService.getGames(providerId, categoryId, producerId)
    },
    getSearchGames (store, { page = 1, pageSize = 100, keyword }) {
      return casinoApiService.searchGames({ page, pageSize, keyword })
    },
    categoryList (store, { page, pageSize, providerId, type, categoryId }) {
      return casinoApiService.categoryList({ page, pageSize, providerId, type, categoryId })
    },
    getGamesByLists (store) {
      return casinoApiService.getGamesByLists().then(data => {
        store.commit('setGamesByLists', data)
        return data
      })
    }
  }
}
