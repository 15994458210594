import SoftSwiss from '@/games/SoftSwiss/Index'
import SoftSwissMain from '@/games/SoftSwiss/Main'
import SoftSwissGamesList from '@/games/SoftSwiss/GamesList'

export const casinoGamesRouter = [
  {
    name: 'Disapp - Casino',
    path: '/games/:type',
    component: SoftSwiss,
    children: [
      {
        name: 'Disapp - Game',
        path: 'main',
        component: SoftSwissMain
      },
      {
        name: 'Disapp - Game',
        path: ':provider',
        component: SoftSwissGamesList
      }
    ]
  }
]
