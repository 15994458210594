import { render, staticRenderFns } from "./InvestWrapper.vue?vue&type=template&id=7bca1a68&scoped=true&"
import script from "./InvestWrapper.vue?vue&type=script&lang=js&"
export * from "./InvestWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bca1a68",
  null
  
)

export default component.exports