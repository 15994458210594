import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { disbetGamesRouter } from '@/router/disbetGamesRouter'
import { casinoGamesRouter } from '@/router/casinoGamesRouter'
import { liveGamesRouter } from '@/router/liveGamesRouter'
import { commonRouter } from '@/router/commonRouter'
import { bonusRouter } from '@/router/bonusRouter'
import { partnersRouter } from '@/router/partnersRouter'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    { // не опускать вниз
      name: 'Disapp - Casino',
      path: '/games/play/:gameId',
      component: () => import('@/games/SoftSwiss/PlayWindow')
    },
    ...commonRouter,
    ...disbetGamesRouter,
    ...casinoGamesRouter,
    ...liveGamesRouter,
    ...bonusRouter,
    ...partnersRouter
  ]
})

router.beforeEach((to, from, next) => {
  if (to.hash && to.hash.length === 7) {
    const fpPromise = FingerprintJS.load()
    fpPromise
      .then(fp => fp.get())
      .then(result => {
        store.dispatch('user/setRefHash', { hash: to.hash.slice(1, to.hash.length), visitorId: result.visitorId })
      })
  }
  next()
})

export default router
