<template>
  <div class="container mt-2">
    <div class="container__level-icon">
      <img alt="level" :src="require(`@/assets/images/vip-club/ru/${level}/level.png`)" />
    </div>
    <div class="container__inner">
      <div class="container__content">
        <div class="container-content-title text-uppercase">
          Vip {{ level }}
        </div>
        <div class="container-content-score font-weight-light text-nowrap">
          {{ $t('vip_club.current_scores')}}: {{ score }}
        </div>
      </div>
    </div>
    <div class="container__right-icon" :class="`right-icon-level right-icon-level-${level}`"></div>
  </div>
</template>

<script>
export default {
  props: ['level', 'score']
}
</script>

<style scoped lang="scss">
.container-content {
  &-title {
    font-size: 24px;
    background: linear-gradient(89.69deg, #B18638 6.1%, #D5B782 43.43%, #FEF0D8 52.39%, #DAB65D 65.92%, #CAB56A 97.73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;

    @media(max-width: 576px) {
      font-size: 20px;
    }
  }

  &-score {
    font-size: 20px;

    @media(max-width: 576px) {
      font-size: 15px;
    }
  }
}

  .container {
    background-image: url(../../../../assets/images/vip-club/ru/background.png);
    background-size: 100% 100%;
    position: relative;
    min-width: 100%;
    left: -24px;
    z-index: 1;
    display: flex;

    @media (max-width: 1200px) {
      left: -21px;
    }

    @media (max-width: 576px) {
      padding: 10px;
    }

    &__inner {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &__level-icon {
      min-width: 60px;
      max-width: 110px;
      width: 110px;
      height: auto;

      @media (max-width: 576px) {
        width: 60px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__right-icon {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      width: 50%;
    }
  }

  .right-icon-level {
    &-1 { background-image: url(../../../../assets/images/vip-club/ru/1/right-icon.png); };
    &-2 { background-image: url(../../../../assets/images/vip-club/ru/2/right-icon.png); };
    &-3 { background-image: url(../../../../assets/images/vip-club/ru/3/right-icon.png); };
    &-4 { background-image: url(../../../../assets/images/vip-club/ru/4/right-icon.png); };
    &-5 { background-image: url(../../../../assets/images/vip-club/ru/5/right-icon.png); };
    &-6 { background-image: url(../../../../assets/images/vip-club/ru/6/right-icon.png); };
    &-7 { background-image: url(../../../../assets/images/vip-club/ru/7/right-icon.png); };
    &-8 { background-image: url(../../../../assets/images/vip-club/ru/8/right-icon.png); };
    &-9 { background-image: url(../../../../assets/images/vip-club/ru/9/right-icon.png); };
    &-10 { background-image: url(../../../../assets/images/vip-club/ru/10/right-icon.png); };
    &-11 { background-image: url(../../../../assets/images/vip-club/ru/11/right-icon.png); };
    &-12 { background-image: url(../../../../assets/images/vip-club/ru/12/right-icon.png); };
    &-13 { background-image: url(../../../../assets/images/vip-club/ru/13/right-icon.png); };
    &-14 { background-image: url(../../../../assets/images/vip-club/ru/14/right-icon.png); };
    &-15 { background-image: url(../../../../assets/images/vip-club/ru/15/right-icon.png); };

    @media(max-width: 576px) {
      visibility: hidden;
    }
  }

  .container__content {
    margin-left: 45px;
    flex: 1;

    @media (max-width: 576px) {
      margin-left: 12px;
    }
  }
</style>
