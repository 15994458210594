<template>
  <div>
    <Message :severity="'error'" v-if="error">{{ error }}</Message>
  </div>
</template>
<script>
export default {
  name: 'DisbetMessage',
  props: {
    DisbetMessage: {
      type: Object,
      required: true
    }
  }
}
</script>
