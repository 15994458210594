import axios from 'axios'
const config = {
  timeout: 60 * 1000,
  headers: { 'Content-Type': 'application/json' }
}

export class Transport {
  engine;

  mapToResponse = (response) =>
    response.data

  request (conf) {
    return this.engine
      .request(conf)
      .then(this.mapToResponse)
  }

  constructor () {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }
    this.engine = axios.create(config)
    this.engine.interceptors.request.use(function (config) {
      config.headers.Language = localStorage.getItem('lang')
      return config
    })

    // this.engine.updateCon
    // this.engine.interceptors.response.use(
    //   response => response,
    //   error => {
    //     // Reject promise if usual error
    //     if (error.response.status !== 401) {
    //       return Promise.reject(error);
    //     }
    //     axios.interceptors.response.eject(interceptor);
    //
    //     return axios.post('/api/refresh_token', {
    //       'refresh_token': this._getToken('refresh_token')
    //     }).then(response => {
    //       saveToken();
    //       error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
    //       return axios(error.response.config);
    //     }).catch(error => {
    //       destroyToken();
    //       this.router.push('/login');
    //       return Promise.reject(error);
    //     }).finally(createAxiosResponseInterceptor);
    //   }
    // );
  }

  setAuthenticationToken ({ accessToken }) {
    console.log('token', accessToken)
    if (accessToken) {
      this.engine.defaults.headers['Authorization'] = `Bearer ${accessToken}`
      localStorage.setItem('token', `Bearer ${accessToken}`)
    }
  }

  get (url) {
    return this.request(
      {
        method: 'GET',
        url: `${url}`
      }
    )
  }

  delete (url) {
    return this.request(
      {
        method: 'DELETE',
        url: `${url}`
      }
    )
  }

  put (url, data) {
    return this.request(
      {
        method: 'PUT',
        url: `${url}`,
        data
      }
    )
  }

  post (url, data) {
    return this.request(
      {
        method: 'POST',
        url: `${url}`,
        data
      }
    )
  }

  customRequest (conf) {
    return this.engine
      .request(conf)
  }
}

export const apiTransport = new Transport()
