<template>
  <div class="banner">
    <div class="banner__card" :style="`background-image: url('${bannerCard.img}')`">
      <div class="banner__card--content" :style="bannerCard.topStyle">
        <div class="banner__card--title">{{ bannerCard.title }}</div>
        <div class="banner__card--desc">{{ bannerCard.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerCard',
  props: {
    bannerCard: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.banner{
  &__card {
    position: relative;
    color: #ffffff;
    display: block;
    width: 100%;
    height: 200px;
    background-size: cover;
    border-radius: 15px;
  }
  &__card--content {
    width: 50%;
    top: 15%;
    right: 8%;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &__card--title {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 700;
  }
  &__card--desc {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .banner {
    &__card--title {
      font-size: 20px;

    }
    &__card--desc {
      font-size: 14px;
    }
  }
}
@media (max-width: 521px) {
  .banner {
    &__card--title {
      font-size: 18px;

    }
    &__card--desc {
      font-size: 13px;
    }
  }
}
@media (max-width: 486px) {
  .banner {
    &__card--content {
      width: 48%;
      right: 12%;
    }
    &__card--desc {
      font-size: 12px;
    }
  }
}

@media (max-width: 448px) {
  .banner {
    &__card--title {
      font-size: 12px;
    }
    &__card--desc {
      font-size: 7px;
    }
  }
}
</style>
