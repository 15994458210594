<template>
  <div class="vip-club-modal">
    <button @click="$router.push($route.path)" class="dm-close-icon">
      <i style="font-size: 1.15rem;" class="pi pi-times"></i>
    </button>
    <div class="vip-club-container">
      <div class="vip-club-header">
        <div class="vip-club-header__title text-uppercase">
          {{ $t('vip_club.title')}}
        </div>
        <div class="text-white vip-club-header__text mt-2">
          {{ $t('vip_club.sub_title')}}
        </div>
        <div class="user-statistics d-flex row m-0 mt-5">
          <div class="col-12 col-md-5">
            <div class="level-container d-flex flex-column align-items-center">
              <img alt="current-level" class="level-container__icon" src="@/assets/images/vip-club/ru/1/level.png"/>
              <p class="level-container__title m-0 text-center mt-2">{{ $t('vip_club.current_level')}}</p>
              <p class="text-white p-text-bold m-0 text-center level-container__subTitle">Vip 1</p>
            </div>
          </div>
          <div class="col-12 col-md-7 mt-4 mt-md-0">
            <div class="statistics-container">
              <div class="statistics__info p-text-bold text-white">
                Имя фамилия
              </div>
              <div class="statistics__progress mt-1">
                <ProgressBar class="progress-bar" v-model="percent" :show-value="false" />
              </div>
              <div class="statistics__progress-info d-flex mt-2">
                <div>{{ $t('vip_club.current')}}:</div>
                <div class="ml-2 p-text-bold text-white text-uppercase flex-grow-1">Vip 1</div>
                <div class="p-text-bold text-white text-uppercase">Vip 2</div>
              </div>
              <div class="statistics-buttons d-flex align-items-center flex-column flex-md-row justify-content-between mt-4">
                <div class="rakeback">
                  <p class="p-text-bold text-white text-uppercase m-0">{{ $t('vip_club.rakeback')}}</p>
                  <p class="p-text-bold text-white m-0 mt-2">0.00</p>
                </div>
                <Button class="convert-button align-self-center mt-4 mt-md-0">{{ $t('vip_club.convert')}}</Button>
              </div>
            </div>
          </div>
        </div>
        <div class="vip-club-header__promo text-white mb-4">
          <span class="vip-club-header__promo-text">
            {{ $t('vip_club.privilege')}}
          </span>
        </div>
      </div>
      <Accordion lazy>
        <AccordionTab :key="level.value" v-for="(level) in this.levels">
          <template #header>
            <AccordionItem :score="level.scores" :level="level.value" />
          </template>
          <AccordionContent :level="level.value" />
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>
<script>

import AccordionItem from '@/modules/vipClub/components/Privilegies/AccordionItem.vue'
import { LEVELS_MAP } from '@/modules/vipClub/constants.ts'
import AccordionContent from '@/modules/vipClub/components/Privilegies/AccordionContent.vue'

export default {
  components: { AccordionContent, AccordionItem },
  data: () => {
    return {
      levels: LEVELS_MAP
    }
  },
  computed: {
    percent () {
      return 10
    }
  }
}
</script>

<style lang="scss">
  .vip-club-modal {
    background-color: #221B36;
    border-radius: 1.25rem;
    margin: auto;
    position: relative;
    max-height: 100%;
    overflow: hidden;
    max-width: 850px;
    overflow-y: scroll;

    @media (max-width: 991px) {
      max-height: 80vh;
    }

    @media (max-width: 576px) {
      width: 100vw;
      border-radius: 0;
      max-width: 100vw;
      max-height: 100vh;
      height: 100vh;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
    & .p-progressbar-determinate .p-progressbar-value {
      background: #B300D1;
    }

    & .dm-close-icon {
      position: absolute;
      font-weight: bold;
      right: 10px;
      top: 17px;
      z-index: 11;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      background: transparent;
      color: #969696;
    }

    & .progress-bar {
      border-radius: 35px;
      background: #121318;
      height: 14px;
    }

    & .convert-button {
      background: #56337C;
      border: none;
      font-weight: bold;
    }
    & .p-accordion-header-link {
      padding: 0 !important;
      border: none !important;
      background: transparent !important;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 20px;
        background: black;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    & .p-accordion-header-link:focus {
      box-shadow: none !important;
      outline: none !important;
    }

    & .p-accordion-content {
      background-color: rgb(40,0,62) !important;
      border: none  !important;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      position: relative;
      padding: 0 1.25rem !important;
    }

    & .rakeback {
      width: 150px;
      padding: 20px 10px;
      background: linear-gradient(270deg, #322653 9.84%, rgba(162, 81, 213, 0.31) 100%);
    }

    & .statistics {
      &__info {
        font-size: 24px;
      }
    }

    & .statistics-container {
      background: #322653;
      border-radius: 20px;
      padding: 23px 33px;
    }

    & .level-container {
      background: #322653;
      border-radius: 20px;
      padding: 17px 60px;
      width: 100%;
      height: 240px;

      @media (min-width: 768px) {
        width: 240px;
      }

      &__title {
        color: #836FBB;
        font-size: 14px;
      }

      &__subTitle {
        font-size: 24px;
      }

      &__icon {
        width: 120px;
        height: 120px;
      }
    }

    & .vip-club-container {
      padding: 32px 43px;
      background-image: url(../../assets/images/vip-club/ru/wing.png);
      background-size: contain;
      background-repeat: repeat-y;

      @media (max-width: 576px) {
        padding: 16px;
      }
    }

    & .vip-club-header {
      display: flex;
      flex-direction: column;

      &__title {
        font-size: 24px;
        color: #9E00FF;
        text-decoration: underline;
      }

      &__text {
        font-size: 19px;
      }

      &__promo {
        margin-top: 40px;
        font-size: 24px;
        text-align: center;
        padding-bottom: 9px;

        &-text {
          background-image: url(../../assets/images/vip-club/ru/wind-left.png), url(../../assets/images/vip-club/ru/wind-right.png);
          background-size: 60px 60px;
          background-repeat: no-repeat;
          background-position: 2% 8px, 94% 8px;
          padding: 20px 50px;
        }
      }
    }
  }
</style>
