<template>
  <div class="giphy">
    <DisbetPreloaderMini v-show="isLoading"/>
    <div class="giphy__body">
      <div v-show="!isLoading" class="giphy__gifs">
        <div class="giphy__gif" v-for="(gif, idx) in gifs" :key="`${gif.id}.${idx}`">
          <img class="gift-img" width="100%" height="100%" @click="onClickGiphy(gif.images.fixed_height_small.url)" :src="gif.images.fixed_height_small.url" alt="">
        </div>
      </div>
      <div v-show="!isLoading" @click.stop=""
           class="giphy__search-block">
        <input class="giphy__search-block--input"
               type="text" v-model="searchText"
               @keyup.stop="onKeyup"
               placeholder="Найти ...">
        <Button @click.stop="searchGifs" icon="pi pi-search"
                class="giphy__search-block--search-btn"/>
      </div>
    </div>
    <div v-show="!isLoading" class="giphy__container-arrow"></div>
  </div>
</template>
<script>
import { giphyTrending, giphySearch } from '@/controller/Giphy'
import { DisbetPreloaderMini } from '../PreloaderMini'

export default {
  name: 'GiphyPopup',
  components: {
    DisbetPreloaderMini
  },
  props: ['onClickGiphy'],
  data: () => ({
    isLoading: false,
    searchText: '',
    gifs: []
  }),
  created () {
    this.loadGifs()
  },
  methods: {
    async loadGifs () {
      this.isLoading = true
      const response = await giphyTrending()
      this.gifs = response.data
      this.isLoading = false
    },
    async searchGifs () {
      if (!this.searchText.trim().length) return
      this.isLoading = true
      const response = await giphySearch(this.searchText.trim())
      if (!response.data.length) {
        return this.loadGifs()
      }
      this.gifs = response.data
      this.searchText = ''
      this.isLoading = false
    },
    onKeyup (e) {
      if (e.keyCode === 13) {
        this.searchGifs()
      }
    }
  }
}

</script>

<style scoped lang="scss">
.giphy {
  width: 100%;
  position: absolute;
  //bottom: -62px;
  //right: -75px;
  z-index: 10;
  user-select: none;
  display: flex;
  flex-direction: column;
  &__body {
    display: flex;
    flex-direction: column;
    height: 430px;
    border-radius: 15px;
    background-color: #111316;
    //margin-bottom: 63px;
  }
  &__gifs {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1 1 auto;
  }
  &__gif {
    position: relative;
    margin: 6px;
    height: 80px;
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #20252f;
  }
  &__iframe {
    position:relative;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  &__overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  &__search-block {
    width: 100%;
    display: flex;
    &--input {
      height: 40px;
      width: 100%;
      padding: 0 46px 0 16px;
      background-color: #1c1f29;
      border-style: none;
      font-size: 14px;
      color: #FFFFFF;
      &:focus::placeholder {
        color: transparent;
      }
    }
    &--search-btn {
      height: 40px;
      width: 30px;
      padding-right: 35px;
      position: absolute;
      border: none;
      right: 10px;
      border-radius: 0;
    }
  }
  &__container-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 6px solid #1c1f29;
    bottom: 57px;
    right: 60px;
    display: none;
  }
}

::-webkit-scrollbar-track {
  border-radius: 20px;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: #7456e0;
}

@media screen and (max-width: 991px) {
  .giphy {
    &__body {
      width: 100%;
    }
    &__gifs-gif {
      max-width: 150px;
    }
  }
}

</style>
