<template>
  <div class="modal-body-my text-center">
    <div class="mt-3">{{ $t('cashbox.transaction.amount') }}</div>
    <div class="transaction--amount">{{ payment.amount }} {{ payment.currencyId }}<img class="transaction--img" :src="payment.currency.src" />
    <div v-if="payment.stateId === 'created'" class="transaction--awaiting"><i class="fa-solid fa-circle-exclamation"></i> {{ $t('cashbox.transaction.awaiting') }}</div>
    <div v-if="payment.stateId === 'canceled'" class="transaction--canceled"><i class="fa-solid fa-ban"></i> {{ $t('cashbox.transaction.canceled') }}</div>
      <div v-if="payment.stateId === 'completed'">
        <div class="transaction--completed"><i class="fa-solid fa-circle-check"></i> {{ $t('cashbox.transaction.completed') }}</div>
        <p v-if="payment.codeId === 'deposit'">{{ $t('cashbox.transaction.arrived') }}</p>
        <p v-if="payment.codeId === 'withdraw'">{{ $t('cashbox.transaction.send') }}</p>
        <div v-if="payment.codeId === 'deposit'" class="row transaction--info text-left">
          <div class="col-6 mt-3">{{ $t('cashbox.transaction.confirmations') }}</div>
          <div class="col-6 text-right text-white mt-3 mb-3">{{ payment.crypto.confirmations }}</div>
          <div class="col-6">{{ $t('cashbox.transaction.network') }}</div>
          <div class="col-6 text-right text-white mb-3">{{ payment.way.id }}</div>
          <div class="col-6">{{ $t('cashbox.transaction.address') }}</div>
          <div class="col-6 text-right text-white mb-3">{{ payment.crypto.addressOut }}</div>
          <div class="col-6">TxID</div>
          <div class="col-6 text-right text-white mb-3">{{ payment.crypto.txidIn }}</div>
          <div class="col-6">{{ $t('cashbox.transaction.date') }}</div>
          <div class="col-6 text-right text-white mb-3">{{ createDate(payment.currency.createdAt) }}</div>
        </div>
      </div>
      <div v-if="payment.codeId === 'withdraw'" class="row transaction--info text-left">
        <div class="col-6 mt-3">ID</div>
        <div class="col-6 text-right text-white mt-3 mb-3">{{ payment.id }}</div>
        <div class="col-6">{{ $t('cashbox.transaction.network') }}</div>
        <div class="col-6 text-right text-white mb-3">{{ payment.way.id }}</div>
        <div class="col-6">{{ $t('cashbox.transaction.address') }}</div>
        <div class="col-6 text-right text-white mb-3">{{ payment.wallet }}</div>
        <div class="col-6">TxID</div>
        <div class="col-6 text-right text-white mb-3"> - </div>
        <div class="col-6">{{ $t('cashbox.transaction.date') }}</div>
        <div class="col-6 text-right text-white mb-3">{{ createDate(payment.currency.createdAt) }}</div>
      </div>
    </div>
    <div>{{ $t('cashbox.transaction.help') }} <br><span @click="openChatra()" class="transaction--support">Live {{ $t('cashbox.transaction.chat') }}</span></div>
    <div v-if="payment.stateId === 'created'" class="transaction--cancel">
      <button @click="cancelWithdraw({paymentId : payment.id})" class="transaction--button">{{ $t('cashbox.transaction.cancel') }}</button>
    </div>
  </div>
</template>
<script>

import dayjs from 'dayjs'
import { mapActions } from 'vuex'

export default {
  name: 'ModalBody',
  props: ['payment'],
  data () {
    return {}
  },
  methods: {
    ...mapActions('cash/withdraw', ['cancelWithdraw']),
    ...mapActions('app', ['openChatra']),
    createDate (date) {
      return dayjs(date).format('YYYY.MM.DD HH:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-body-my {
  width: 427px;
  height: 35rem;
  padding-left: 3px;
}

@media (max-width: 576px) {
  .modal-body-my {
    width: 100%;
    height: 100%;
  }
}
.transaction {
  &--amount {
    font-weight: 700;
    color: white;
    font-size: 1.5rem;
  }
  &--canceled {
    color: #d55f6b;
    font-size: 1.3rem;
  }
  &--awaiting {
    color: goldenrod;
    font-size: 1.3rem;
  }
  &--completed {
    color: #51f376;
    font-size: 1.3rem;
  }
  &--cancel {
    min-width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
  }
  &--button {
    width: 100%;
    border-radius: 5px;
    font-weight: normal;
    font-size: 1rem;
    height: 44px;
    color: white;
    background-color: #7f64e3;
    display: block;
    text-align: center;
  }
  &--support {
    color: yellow;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
  &--img {
    padding: 5px;
    width: 33px;
    margin-top: -5px;
  }
  &--info {
    color: #969696;
    font-size: 1rem;
    word-break: break-word;
    font-weight: 400;
    line-height: 30px;
  }
}

p {
  color: #969696;
  font-size: 1rem;
  font-weight: 400;
}

hr {
  border-top: 1px solid rgb(255 255 255 / 10%);
}
</style>
