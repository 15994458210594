import { render, staticRenderFns } from "./AirDropHeader.vue?vue&type=template&id=68566bc4&scoped=true&"
import script from "./AirDropHeader.vue?vue&type=script&lang=js&"
export * from "./AirDropHeader.vue?vue&type=script&lang=js&"
import style0 from "./AirDropHeader.vue?vue&type=style&index=0&id=68566bc4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68566bc4",
  null
  
)

export default component.exports