import { DISBET_AUTH_URL, DISBET_OAUTH_URL } from '../../constants'
import { apiTransport } from './transport'

class AuthApiService {
  constructor (transport) {
    this.transport = transport
  }

  login (login, password) {
    return this.transport.post(`${DISBET_AUTH_URL}/login`, { login, password }).then(data => {
      apiTransport.setAuthenticationToken(data)
    })
  }

  loginByTfaToken ({ token, code }) {
    return this.transport.post(`${DISBET_AUTH_URL}/loginByTfaToken`, { token, code })
  }

  oauthLogin (data, provider) {
    return this.transport.post(`${DISBET_OAUTH_URL}/${provider}`, data).then(data => {
      apiTransport.setAuthenticationToken(data)
    })
  }

  logout () {
    // return this.transport.post(`${DISBET_AUTH_URL}/logout`).then(data => {
    //   apiTransport.setAuthenticationToken({
    //     accessToken: ''
    //   })
    // })
    return new Promise((resolve, reject) => {
      apiTransport.setAuthenticationToken({
        accessToken: '123'
      })
      resolve()
    })
  }

  refreshToken () {
    return this.transport.post(`${DISBET_AUTH_URL}/refreshToken`)
  }
}

export const authApiService = new AuthApiService(apiTransport)
