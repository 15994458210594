<template>
  <div class="card-fortune card text-white h-100">
    <div class="card-body d-flex flex-column align-items-center">
      <p class="p-text-bold text-wrap card-body__title text-center">Колесо фортуны</p>
      <Button class="mt-3 p-button p-component w-100 card-button">Подробнее</Button>
    </div>
  </div>
</template>

<script>
export default {
  props: {}
}
</script>

<style lang="scss" scoped>
.card {
  &-fortune {
    border: none;
    color: white;
    min-height: 260px;
    padding: 40% 0;
    border-radius: 25px;
    overflow: hidden;
    background: rgb(142,67,154);
    background: radial-gradient(circle, rgba(142,67,154,1) 0%, rgba(46,18,125,1) 100%);
  }

  &-button {
    border-radius: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  &-body {
    &__title {
      font-size: 2rem;
      text-transform: uppercase;
      margin: 0;

      @media (max-width: 576px) {
        font-size: 25px;
      }
    }
  }
}

</style>
