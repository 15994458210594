<template>
  <div class="profile__tooltip">
    <div class="profile__tooltip-content">
      <div class="profile__tooltip-content-item-header">
        <DisbetCustomAvatar class="cursor-pointer" @click="openModalUser(user)" style="width: 42px" :src="user.avatar" :username="user.nick" :size="40"></DisbetCustomAvatar>
        <span @click="openModalUser(user); toggle()" class="profile__tooltip-content-text-nick cursor-pointer font-weight-bold">{{ user.nick }}</span>
        <!-- Settings profile -->
        <span @click="openModalUser(user); toggle()" class="profile__tooltip-content-text settings"><i class="far fa-user-circle"></i> {{ $t('profile.myprofile') }}</span>
        <!--  -->
      </div>
      <hr class="profile__tooltip-content-line">

      <!--  -->
      <!-- Menu -->
      <div class="profile__tooltip-content-menu">
        <div @click="$router.push('/settings'); toggle()" class="profile__tooltip-content-item profile-button">
        <span class="profile__tooltip-content-icon"><i class="fas fa-user-cog"></i></span>
          <span class="profile__tooltip-content-text">{{ $t('menu.tooltip.settings') }}</span>
        </div>
        <div @click="openPromoModal(); toggle()" class="profile__tooltip-content-item profile-button">
        <span class="profile__tooltip-content-icon"><i class="fas fa-qrcode"></i></span>
          <span class="profile__tooltip-content-text">{{ $t('profile.promo') }}</span>
        </div>
        <div  @click="toggle()" >
          <router-link :to="menu.link" :key="idx" v-for="(menu, idx) in menu" class="profile__tooltip-content-item">
            <span class="profile__tooltip-content-icon"><i :class="menu.icon"></i></span>
            <span class="profile__tooltip-content-text">{{ $t(menu.name) }}</span>
          </router-link>
        </div>

        <div @click="openChatra(); toggle()" class="profile__tooltip-content-item profile-button ">
          <span class="profile__tooltip-content-icon"><i class="fas fa-life-ring"></i></span>
          <span class="profile__tooltip-content-text">{{ $t('profile.support') }}</span>
        </div>
<!--        <div @click="$router.push('/bonus'); toggle()" class="profile__tooltip-content-item profile-button ">-->
<!--          <span class="profile__tooltip-content-icon"><i class="fas fa-gift"></i></span>-->
<!--          <span class="profile__tooltip-content-text">{{ $t('profile.bonus') }}</span>-->
<!--        </div>-->
      </div>
      <div @click="onLogout" class="profile__tooltip-content-item exit cursor-pointer">
        <span class="profile__tooltip-content-icon"><i class="far fa-arrow-alt-circle-right"></i></span>
        <span class="profile__tooltip-content-text exit-text">{{ $t('menu.tooltip.exit') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { DisbetCustomAvatar } from '@/common/components'
import ModalUserInfo from '@/modules/userStatistic/components/Modal/ModalUserInfo'
import ModalPromo from '@/modules/bonus/Modal/ModalPromo'

export default {
  components: {
    DisbetCustomAvatar
  },
  name: 'MenuTooltipProfile',
  data () {
    return {
      value: 20,
      menu: [
        {
          name: 'profile.helpdesk',
          link: '/helpdesk',
          icon: 'fas fa-book'
        }
      ]
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('app', ['openChatra']),
    toggle () {
      this.$emit('close')
    },
    onLogout () {
      this.logout().then(() => {
        this.$router.replace('/main')
      })
    },
    openModalUser (currentUser) {
      console.log(this.user)
      console.log(currentUser)
      this.$modal.show(ModalUserInfo, { currentUser: currentUser.id }, {
        clickToClose: false,
        adaptive: true,
        height: '100%',
        width: '100%'
      })
    },
    openPromoModal () {
      this.$modal.show(ModalPromo, {}, {
        clickToClose: false,
        adaptive: true,
        height: '100%',
        width: '100%'
      })
    }

  },
  computed: {
    ...mapGetters('user', {
      user: 'user'
    })
  }
}
</script>

<style scoped lang="scss">
.profile {
  &__tooltip-content {
    min-height: 350px;
    width: 363px;
    padding: 15px;
    background: #0F1114;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 15px;
    @media (max-width: 450px) {
      border-radius: 0 0 15px 15px;
    }
    &:hover {
      cursor: default;
    }
      &:before {
      }
      &-line {
        background-color: rgba(150, 150, 150, 0.2);
        height: 1px;
        width: 100%;
      }
      &-menu {
        width: 334px;
        max-height: 218px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 25px 25px 15px 25px;
        background-color: #15181F;
        border-radius: 15px;
      }
    }

  &__tooltip-content-item {
    height: 25px;
    display: flex;
    width: 60%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
      &:hover {
        color: #fff;
      }
    &-header {
      height: 52px;
      width: 100%;
      display: flex;
      align-items: center;
      background: #0F1114;
    }
  }

  &__tooltip-content-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #969696;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    font-size: 18px;
  }

  &__tooltip-content-text {
    font-family: 'Supermolot', sans-serif;
    display: inline-block;
    color: #969696;
    font-weight: 300;
    font-size: 14px;
    transition: all .2s ease;
      &:hover {
          transition: all .2s ease;
          color: #fff;
        }
    &-nick {
      color: #ffffff;
      font-size: 16px;
      margin-left: 6px;
      font-family: 'Supermolot', sans-serif;
    }
  }
}

.profile__tooltip-content-text:hover .profile__tooltip-content-icon {
  color:#fff;
}

.settings {
  margin-left: auto;
}

.settings:hover {
  cursor: pointer;
  color: #fff;
}

.percent {
  font-size: 10px;
  margin: 12px 0 0 14px;
  &:hover {
    color: #969696;
    cursor: default;
  }
}

.orange span {
  background-color: #fecf23;
  background-image: linear-gradient(to bottom, #FAFF04, #FF9A04);
}

.exit {
  margin: 11px 0 -2px 25px;
  width: 80px;
}

.exit-text {
  color: #8f9090;
}
.fa-arrow-alt-circle-right{
  transition: all .4s ease;
}
.exit:hover .exit-text {
  transition: all .2s ease;
  color: #fff;
  cursor: pointer;
}
.exit:hover .fa-arrow-alt-circle-right{
  transform: rotate(180deg);
  transition: all .4s ease;
}
.profile-button {
  :hover {
    cursor: pointer;
  }
}
@media (max-width: 991px) {
  .profile {
    &__tooltip-content {
      top: 45px;
    }
  }
}

@media (max-width: 450px) {
  .profile {
    &__tooltip-content {
      width: 100%;
      &-menu {
        width: calc(95vw - 30px);
      }
      &-line {
        width: 100vw;
      }
    }
  }
  .progress-bar {
    width: calc(95vw - 90px);
  }
}

.p-overlaypanel:after, .p-overlaypanel:before {
  left: calc(var(--overlayArrowLeft, 0) + 1.45rem);
}
</style>
