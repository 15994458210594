<template>
  <nav class="controls-bar d-lg-none">
    <div class="pos-container menu">
      <router-link tag="div" :to="'/main'" active-class="text-white" class="nav-button">
        <div class="icon">
          <i class="fas fa-home"></i>
        </div>
        <p>{{ $t('main.footer.page_rates')}}</p>
      </router-link>
      <router-link tag="div" :to="'/games/casino/main'" active-class="text-white" class="nav-button">
        <div class="icon-svg">
          <SvgSlots class="vector"/>
        </div>
        <p>{{ $t('main.footer.page_slots') }}</p>
      </router-link>
      <router-link tag="div" :to="'/games/live/main'" active-class="text-white" class="nav-button">
        <div class="icon-svg">
          <SvgLive class="vector"/>
        </div>
        <p>{{ $t('main.footer.page_live') }}</p>
      </router-link>
      <div  @click="openChat" class="nav-button" v-if="isChatActive">
        <div class="icon">
        <i class="fas fa-comment-alt"></i>
        </div>
        <p>{{ $t('main.footer.page_chat') }}</p>
      </div>
      <div  @click="setIsLeftAsideVisible" class="nav-button">
        <div class="icon">
          <i class="fas fa-bars"></i>
        </div>
        <p>{{ $t('main.footer.page_aside') }}</p>
      </div>
      <div class="menu-item" :class="{ active: isOpen }">
        <div ref="toggleMenu" @click.stop="isOpen = !isOpen" class="nav-button">
          <div class="icon">
          <i class="fas fa-ellipsis-h"></i>
          </div>
          <p>{{ $t('main.footer.page_more') }}</p>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="sub-menu row" v-if="isOpen">
        <div v-for="(item, i) in services" :key="i" class="sub-menu--item col-6">
          <router-link tag="button" active-class="active-menu" :to="item.link">{{ $t(item.title) }}</router-link>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>

import Chat from '@/modules/chat/Chat'
import SvgSlots from '@/assets/svg/menu/slots.svg'
import SvgLive from '@/assets/svg/menu/live.svg'

export default {
  name: 'DisbetFooterMenuMobile',
  components: {
    SvgSlots,
    SvgLive
  },
  props: ['setIsLeftAsideVisible'],
  data () {
    return {
      isOpen: false,
      isChatActive: false,
      services: [
        {
          title: 'main.footer.promotion',
          link: '/bonus'
        },
        {
          title: 'main.footer.support',
          link: '/helpdesk/faq'
        }
      ]
    }
  },
  methods: {
    openChat () {
      this.$modal.show(Chat, {}, {
        clickToClose: false,
        adaptive: true,
        height: '100%',
        width: '100%',
        pivotY: 0.35
      })
    },
    clickBodyListener () {
      this.isOpen = false
    }

  },
  watch: {
    '$route' (to, from) {
      document.getElementById('title').innerHTML = to.name
      this.isOpen = false
    },
    isOpen: function (val) {
      this.$refs.toggleMenu.style['color'] = val ? 'white' : '#90929f'
    }
  },
  mounted () {
    document.addEventListener('click', this.clickBodyListener)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickBodyListener)
  }
}
</script>

<style scoped lang="scss">

.nav-button {
  position: relative;
  padding: 0 0.3em;
  color: var(--icon-color);
  cursor: pointer;
  background-color: lighten(#2C303D, 3);
  border: none;
  font-size: 20px !important;
  outline: 0;
  text-align: center;
}

p {
  margin-bottom: 0;
}

.nav-button p {
  font-size: 0.8rem;
  font-weight: 300;
  padding: 5px;
  margin-top: -25px;
}

.controls-bar {
  width: 100%;
  background-color: #1C1F29;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
}

.pos-container {
  display: -webkit-box;
  margin-top: 10px;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
}

.controls-menu {
  bottom: 38px;
  width: 100%;
  background-color: #1c1f29;
  padding: 15px;
  height: 100px;
  position: fixed;
}

.left-menu {
  max-width: 110px;
  width: 110px;
  height: 100%;
  overflow-y: scroll;
}

.games-toggle-button {
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 15px;
  height: 42px;
  width: 42px;
  padding: 10px;
  border: 1px solid;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 991px) {
  .portable-none {
    display: none;
  }
}

nav .menu {
  position: relative;
  z-index: 1;
  background-color: lighten(#2C303D, 3);
  height: 67px;
  margin-top: 0;
}

nav .menu-item {
  position: relative;
  text-align: center;
  display: flex;
}

nav .menu-item a {
  text-decoration: none;
}

nav .sub-menu {
  position: fixed;
  bottom: 45px;
  background-color: #272b36;
  left: 0;
  padding-bottom: 25px;
  margin: auto;
  width: 100%;
  &--item {
    background-color: #272b36;
    padding: 10px;
    display: flex;
    z-index: 1;
    button {
      width: 100%;
      height: 44px;
      background-color: #1a1d24;
      padding-left: 20px;
      border-radius: 5px;
      color: white;
      text-align: left;
    }
  }
}

.nav-button {
  font-size: 20px;
}

.fa, .fas {
  font-size: 1rem;
}
.icon {
  width: 45px;
  background-color: lighten(#2C303D, 3);
  transform: translateY(-2rem);
  border-radius: 35px;
  &-svg {
    width: 45px;
    background-color: lighten(#2C303D, 3);
    -webkit-transform: translateY(-2rem);
    transform: translateY(-2rem);
    border-radius: 35px;
  }
}
.vector {
  fill: #90929f;
  width: 25px;
}
.fa-home {
  color: #90929f;
  font-size: 20px;
}
</style>
