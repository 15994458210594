<template>
    <div class="range">
        <svg id="svg" width="200" height="16" version="1.1">
            <line x1="0" y1="3" x2="200" y2="3" stroke="#15181f" stroke-width="5" stroke-linecap="round"/>
            <line x1="0" y1="13" x2="200" y2="13" stroke="#15181f" stroke-width="5" stroke-linecap="round"/>
            <line ref="line1" x1="0" y1="3" x2="100" y2="3" stroke="#17a2b8" stroke-width="5" stroke-linecap="round"/>
            <line ref="line2" x1="100" y1="13" x2="200" y2="13" stroke="#f6519f" stroke-width="5" stroke-linecap="round"/>
        </svg>
    </div>
</template>

<script>
export default {
  props: ['percent'],
  watch: {
    percent: function () {
      this.$refs.line1.setAttribute('x2', this.percent * 2)
      this.$refs.line2.setAttribute('x1', 200 - this.percent * 2)
    }
  }
}
</script>

<style scoped>
    .range{
        display: block;
        width: 100%;
    }
    svg:not(:root) {
        overflow: hidden;
    }
</style>
