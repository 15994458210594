<template>
  <div class="modal-body-my">
    <div class="promo-modal">
      <div class="promo-modal__container">
        <div class="p-float-label justify-content-start">
          <p class="text-grey">{{ $t('profile.active_promo')}}</p>
          <InputText
            v-model.trim="codePromo"
            :placeholder="( $t('profile.promo_input') )"
            type="text"
            class="p-form-control"
          />
          <div class="invalid-feedback invalid-input invalid-confirm">
            {{ $t('profile.security.password_not_match') }}
          </div>
        </div>
        <div class="text-center">
          <Button @click="activate" class="button-submit p-button">
            {{ $t('bonus.promo.activate').toUpperCase()}}
          </Button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { mapActions } from 'vuex'

export default {
  name: 'ModalBody',
  data: () => ({
    codePromo: ''
  }),
  methods: {
    // todo remove this action from chat
    ...mapActions('chat', ['activateBonus']),
    activate () {
      this.activateBonus({ code: this.codePromo })
        .then(() => {
          this.$emit('close')
          this.$noty.success('success')
        }).catch(e => {
          this.$noty.error(e.response.data.message)
        })
    }
  }
}
</script>
<style  lang="scss" scoped>
.modal-body-my {
  width: 427px;
  padding-left: 3px;
}

.p-button {
  background: rgba(56, 58, 68, 0.84);
  border-radius: 15px;
  border: none;
  height: 31px;
  padding: 0 49px;
  margin-top: -10px;
}

.p-button:hover {
  background: rgba(86, 89, 103, 0.84);
}

.promo-modal {
  height: calc(90vh - 20px);
  max-height: 223px;
  overflow-y: auto;
  border-radius: 15px;
  margin: 14px 0 -14px -17px;
  width: 455px;

  &__container {
    background-color: #292C38;
    width: 455px;
    height: 423px;
    padding: 0 40px;
  }
  &__avatar {
    text-align: center;
    margin-bottom: 35px;
    padding-top: 20px;
    &-border {
      border: 4px solid #4A4C56;
      box-sizing: content-box;
      border-radius: 50%;
      height: 119px;
      width: 119px;
      margin: 0 auto;
    }
  }
}

.promo-modal::-webkit-scrollbar {
  width: 0;
}

.text-grey {
  color: #969696;
  font-size: 16px;
  margin-left: 1rem;
  padding-top: 1rem;
  max-width: 20rem;
}

.p-form-control {
  height: 55px;
}

.button-submit {
  height: 55px;
  margin-top: 20px;
  margin-bottom: 40px;
  border-radius: 15px;
  width: 252px;
  background-color: #7C4EFF;
}

.promo-modal__avatar-img {
}

@media (max-width: 576px) {
  .promo-modal {
    background: #1c1f29;
    align-items: flex-start;
    width: 100vw;
    max-height: 100vh;
    height: calc(100vh - 55px);
    &__container {
      background-color: #292C38;
      width: 100%;
      height: 100%;
      padding: 0 40px;
    }
  }
}
@media (max-width: 576px) {
  .modal-body-my {
    width: 100%;
    height: 100%;
  }
}

</style>
