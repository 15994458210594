import {
  accountApiService
} from '@/common/services/apiServices'

export default {
  namespaced: true,
  state: {
    accounts: [],
    currentAccount: {
      id: 0,
      balance: 0
    }
  },
  getters: {
    getActiveAccounts (state, getters, rootState, rootGetters) {
      return state.accounts
        .filter(acc => !acc.blocked && acc.enabled)
        .sort((a, b) => b.balance - a.balance)
        .map(acc => {
          return {
            ...acc,
            currency: rootGetters['currency/getCurrencyById'](acc.currencyId)
          }
        })
    }
  },
  actions: {
    changeAccount (store, { accountId }) {
      return accountApiService.changeAccount(accountId).then(account => {
        store.commit('changeAccount', account)
      })
    },
    setBasicCurrency (store, currencyId) {
      return accountApiService.setBasicCurrency({ currencyId }).then(account => {
        // store.commit('changeAccount', account)
      })
    }
  }
}
