import { apiTransport } from './transport'

class AccountApiService {
  constructor (transport) {
    this.transport = transport
  }

  changeAccount (accountId) {
    return this.transport.post(`/api/player/fincore/account/setActiveAccount`, { accountId })
  }

  setActiveAccount (id) {
    return this.transport.post('/api/player/fincore/account/setActiveAccount', { id })
  }

  setBasicCurrency ({ currencyId }) {
    return this.transport.post('/api/player/fincore/account/setBasicCurrency', { currencyId })
  }

  setAccountEnabled (data) {
    return this.transport.post('/api/player/fincore/account/toggleAccounts', { schema: data })
  }

  accountVariables () {
    return this.transport.post('/api/player/fincore/account/accountVariables')
  }
}

export const accountApiService = new AccountApiService(apiTransport)
