<template>
  <div class="card-rackback">
    <div class="card-body d-flex justify-content-center align-items-start align-items-lg-center flex-column flex-lg-row">
      <p class="card-body__title">Рейкбек</p>
      <Button class="p-button p-component card-button">Подробнее</Button>
    </div>
  </div>
</template>

<script>
export default {
  props: {}
}
</script>

<style lang="scss" scoped>
.card {
  &-rackback {
    margin-top: 15px;
    border-radius: 25px;
    color: white;
    background: rgb(142, 67, 154);
    background: radial-gradient(circle, rgba(142, 67, 154, 1) 0%, rgba(46, 18, 125, 1) 100%);
  }

  &-button {
    border-radius: 16px;
    margin-left: 15px;
  }

  &-body {
    &__title {
      font-size: 32px;
      margin: 0;
      font-weight: bold;
      line-height: 14px;

      @media (max-width: 576px) {
        font-size: 28px;
      }
    }
    &__button {
      margin-top: 16px;
    }
  }
}
</style>
