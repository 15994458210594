import { apiTransport } from './transport'
import { DISBET_ORIGIN_URL } from '@/common/constants'

class CasinoApiService {
  constructor (transport) {
    this.transport = transport
  }

  openGame (gameId, currency) {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/openGame`, { gameId, currency })
  }

  getCategories () {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/getCategories`)
  }

  getProducers (data) {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/getProducers`, data)
  }

  getProviders ({ type }) {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/getProviders`, {
      type
    })
  }

  getGames (providerId = null, categoryId = null, producerId = null) {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/getGames`, { providerId, categoryId, producerId })
  }

  categoryList ({ page, pageSize, providerId, type, categoryId }) {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/categoryList`, { page, pageSize, categoryId, type, providerId })
  }

  getGamesByLists () {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/list/list`)
  }

  searchGames ({ page, pageSize, keyword }) {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/searchGames`, { page, pageSize, keyword })
  }
  getLastLogs () {
    return this.transport.post(`${DISBET_ORIGIN_URL}/games/getLastLogs`)
  }
}

export const casinoApiService = new CasinoApiService(apiTransport)
