<template>
    <component @setPage="setPage" v-if="current" :is="current" :props="currentProps"/>
</template>
<script>
import AccountChooserList from '@/common/components/AccountChooser/AccountChooserList'
import AccountChooser from '@/common/components/AccountChooser/AccountChooserActiveCurrency'

const PAGE_CURRENCY_CHOOSER = 'PAGE_ACCOUNT_CHOOSER'
const PAGE_ACTIVE_CURRENCY = 'PAGE_ACCOUNT_CURRENCY'

export default {
  components: { AccountChooserList, AccountChooser },
  props: ['onChooseAccount'],
  name: 'CommonAccountChooser',
  data () {
    return {
      page: PAGE_CURRENCY_CHOOSER,
      current: null,
      currentProps: {}
    }
  },
  methods: {
    setPage (page) {
      this.page = page
      switch (page) {
        case PAGE_CURRENCY_CHOOSER:
          this.current = AccountChooserList
          this.currentProps = {
            onChooseAccount: this.onChooseAccount
          }
          break
        case PAGE_ACTIVE_CURRENCY:
          this.current = AccountChooser
          this.currentProps = {}
          break
      }
    }
  },
  created () {
    this.setPage(PAGE_CURRENCY_CHOOSER)
  }
}
</script>
<style lang="scss" scoped>

</style>
