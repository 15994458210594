<template>
    <div class="chat-container">
      <Chat />
    </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import Chat from '@/modules/chat/Chat'

export default {
  components: {
    Chat
  },
  computed: {
    ...mapGetters('user', {
      user: 'user',
      checkAuth: 'auth',
      checkAdmin: 'admin',
      chatBanned: 'chatBanned'
    })
  }
}

</script>
<style scoped lang="scss">

.chat-container {
  height: 100%;
  background-color: transparent;
}

.hide {
  transform: translateX(120px) !important;
  transition: .3s !important;
  width: 0 !important;
  margin-left: -5px;
}

.open {
  transform: rotateX(180deg);
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  background: #fff;
  position: absolute;
  top: 40%;
  left: -40px;
  cursor: pointer;
  color: #1C1F29;;
  font-size: 20px;
  transition: .3s;
  z-index: 9;
}

.close {
  transform: rotateX(0deg);
  transform: translateX(-90px) !important;
  transition: .3s;
  color: #1C1F29;
  font-size: 20px;
  margin-left: -25px;
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 0;
  height: 100px;
}

::-webkit-scrollbar-button {
  background-color: #787878;
  display: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #787878;
}

::-webkit-scrollbar-thumb {
  height: 100px;
  background-color: #F5F5F5;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background-color: #787878;
}

::-webkit-resizer {
  background-color: #666;
}
</style>
