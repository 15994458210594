<template>
  <div class="modal-body-my">
  </div>
</template>
<script>
import { modalsNames } from '@/utils/constants'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ModalBody',
  props: ['bonus'],
  data () {
    return {}
  },
  components: {},
  computed: {
    ...mapGetters('cash/deposit', ['getDepositBonuses', 'getSelectedBonus'])
  },
  methods: {
    ...mapMutations('cash/deposit', ['setSelectBonus']),
    setBonus (bonus) {
      this.setSelectBonus(bonus)
      this.$modal.hide(modalsNames.DEPOSIT_BONUS_LIST)
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-body-my {
  width: 427px;
  padding-left: 3px;
}

@media (max-width: 576px) {
  .modal-body-my {
    width: 100%;
    height: 100%;
  }
}
.bonus {
  height: 190px;
  border-radius: 15px;
  background-image: radial-gradient(circle at 50% 50%, #f6ce22, #ff9900 67%);
  margin-top: 30px;
  cursor: pointer;
  margin-left: 5px;
  min-width: 95%;
  transition: all 0.3s ease-in-out;
  padding: 35px;
  &:hover {
    transform: translate(0,-10px);
  }
  &--percent {
    font-size: 1.5rem;
    font-weight: 600;
  }
  &--amount {
    font-weight: 700;
    font-size: 1rem;
  }
}
.bonus-type0{
  background-image: radial-gradient(circle at 50% 50%, #f6ce22, #ff9900 67%);
}
.bonus-type1{
  background-image: radial-gradient(circle at 50% 50%, #e3e3e3, #b95dff 67%);
}
.bonus-type2{
  background-image: radial-gradient(circle at 50% 50%, #f6ce22, #ff5722 67%);
}
p {
  color: black;
  text-align: center;
  line-height: 20px;
}
.fa-plus {
  border-radius: 5px;
  width: 2.375rem;
  background: linear-gradient(344deg, rgba(255,252,10,1) 0%, rgba(255,166,0,1) 100%);
  padding: 2px;
  height: 1.375rem;
  border: 2px solid yellow;
}
.active {
  transform: translate(0,-10px);
}

</style>
