import { apiTransport } from './transport'

class CurrencyApiService {
  constructor (transport) {
    this.transport = transport
  }

  getCurrencyList () {
    return this.transport.post(`/api/player/currency/list`)
  }

  getCurrencyCourse () {
    return this.transport.post(`/api/player/currency/rate/lastRates`)
  }

  convert (from, to, amount, swap) {
    return this.transport.post(`/api/player/currency/rate/convert`, { from, to, amount, swap })
  }
}

export const currencyApiService = new CurrencyApiService(apiTransport)
