import config from '@/config/configGiphy'

export async function giphyTrending () {
  const options = {
    method: 'GET',
    mode: 'cors'
    // credentials: 'include',
    // headers: {
    //   Authorization: token
    // }
  }

  const params = {
    api_key: config.giphyApiKey,
    limit: 20, // default 25
    offset: 0, // default 0
    // rating: r,
    random_id: 'uY2PfRowQu2UnlAjoihcdEOyfwy6Ulgi5GyIMzVjTo'
  }

  const response = await fetch(composeUrl('https://api.giphy.com/v1/gifs/trending', params), options)
  const data = await response.json()
  return data
}

export async function giphySearch (text) {
  const options = {
    method: 'GET',
    mode: 'cors'
    // credentials: 'include',
    // headers: {
    //   Authorization: token
    // }
  }

  const params = {
    api_key: config.giphyApiKey,
    limit: 20, // default 25
    offset: 0, // default 0
    q: text,
    lang: 'en',
    // rating: 'g',
    random_id: 'uY2PfRowQu2UnlAjoihcdEOyfwy6Ulgi5GyIMzVjTo'
  }

  const response = await fetch(composeUrl('https://api.giphy.com/v1/gifs/search', params), options)
  const data = await response.json()
  return data
}

function composeUrl (url, params) {
  const serialized = serializeParams(params)
  return serialized.length > 0 ? `${url}?${serialized}` : url
}

// function isJSON(obj) {
//   return obj.constructor === Object || obj.constructor === Array
// }

// function serializeBody(body, headers) {
//   if (body) {
//     if (isJSON(body)) {
//       headers['Content-Type'] = 'application/json'
//       return JSON.stringify(body)
//     } else {
//       headers['Content-Type'] = 'application/x-www-form-urlencoded'
//       return body.toString()
//     }
//   }

//   return null
// }

function serializeObject (obj) {
  const result = []
  for (let name in obj) {
    result.push(`${name}=${encodeURIComponent(obj[name])}`)
  }
  return result.join('&')
}

function serializeParams (params) {
  if (!params) return ''

  switch (params.constructor) {
    case FormData:
    case URLSearchParams:
      return params.toString()
    case String:
      return params
    default: // object
      return serializeObject(params)
  }
}
