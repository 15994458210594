<template>
  <transition name="fade">
    <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MiniPreloader'
}
</script>

<style scoped>
  .loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 99999;
    height: 100%;
    width: 100%;
    top: 0
  }
  .fade-leave-active {
    opacity: 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .loader {
    position: absolute;
    margin-top: 100px;
    width: 100px;
    height: 100px;
  }

  .loader::before {
    content: "";
    display: block;
    position: absolute;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
  }

  .loader::before {
    width: 50px;
    height: 50px;
    border-bottom-color: yellow;
    border-right-color: yellow;
    border-top-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
    top: 0;
    left: 20px;
    animation: rotate-animation 0.9s linear 0s infinite;
  }

  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
</style>
