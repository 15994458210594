import BonusDeposit from '@/common/components/modals/depositBonuses/BonusDeposit'
import BonusDepositInfo from '@/common/components/modals/depositIncomnigBonus/BonusDepositInfo'
import TransactionDetails from '@/common/components/modals/transactionDetails/TransactionDetails'
import DacModal from '@/common/components/Header/modals/DacModal'
import Vue from 'vue'
import { modalsNames } from '@/utils/constants'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    closeModal (store, name) {
      Vue.prototype.$modal.hide(name)
    },
    openDacModal () {
      Vue.prototype.$modal.show(
        DacModal,
        {},
        {
          name: modalsNames.DAC,
          clickToClose: false,
          adaptive: true,
          height: '100%',
          width: '100%'
        }
      )
    },
    openDepositBonusModal () {
      Vue.prototype.$modal.show(
        BonusDeposit,
        {},
        {
          name: modalsNames.DEPOSIT_BONUS_LIST,
          clickToClose: false,
          adaptive: false,
          height: '100%',
          width: '100%'
        }
      )
    },
    openTransactionDetailsModal (store, data) {
      Vue.prototype.$modal.show(
        TransactionDetails,
        {
          payment: data
        },
        {
          name: modalsNames.TRANSACTION_DETAILS,
          clickToClose: false,
          adaptive: false,
          height: '100%',
          width: '100%'
        }
      )
    },
    openBonusDepositInfoModal (store, data) {
      Vue.prototype.$modal.show(
        BonusDepositInfo,
        {
          bonus: data
        },
        {
          name: modalsNames.DEPOSIT_BONUS_INFO,
          clickToClose: false,
          adaptive: false,
          height: '100%',
          width: '100%'
        }
      )
    }
  }
}
