import Profile from '@/pages/Profile/components/ProfileComponent'
import Main from '@/pages/Main/Main'
import Terms from '@/pages/Terms/Terms'
import Policy from '@/pages/Policy/Policy'
import E404 from '@/pages/404/E404'
import Helpdesk from '@/pages/Helpdesk/Helpdesk'
import Faq from '@/pages/Helpdesk/Faq'

export const commonRouter = [
  { path: '/', redirect: '/main' },
  { name: 'Disapp - Not Found', path: '*', component: E404 },
  { name: 'Disapp - Main', path: '/main', component: Main },
  { name: 'Disapp - Settings', meta: { auth: true }, path: '/settings', component: Profile },

  {
    name: 'Disapp - Helpdesk',
    path: '/helpdesk',
    redirect: '/helpdesk/faq',
    meta: { auth: 'false' },
    component: Helpdesk,
    children: [
      {
        name: 'Disapp - FAQ',
        path: '/helpdesk/faq',
        component: Faq
      },
      {
        name: 'Disapp - Policy',
        path: '/helpdesk/policy',
        component: Policy
      },
      {
        name: 'Disapp - Terms of Use',
        path: '/helpdesk/terms',
        component: Terms
      }
    ]
  }
]
