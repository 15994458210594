<template>
  <div>
    <div class="row mt-3">
      <div class="col-12 casino-header col-md-6">
        <a @click="$router.go(-1)">
          <i class="pi pi-chevron-left"></i>
        </a>
        <div class="ml-3">
          <span v-if="$route.params.type === 'casino'">{{ $t('navigations.casino') }}</span>
          <span v-if="$route.params.type === 'live'">{{ $t('navigations.livegames') }}</span>
        </div>
      </div>
      <Menu class="col-12 col-lg-6" />
    </div>
    <GamesList :page="1" :pageSize="10"/>
  </div>
</template>

<script>

import GamesList from '@/common/components/GamesList/GamesList'
import Menu from './Menu'

export default {
  name: 'CasinoCategory',
  data: () => ({}),
  components: {
    GamesList,
    Menu
  }
}
</script>

<style scoped lang="scss">
.casino-header {
  display: flex;
  font-weight: 700;
  font-size: 24px;
  align-items: center;
  color: white;
}
.pi-chevron-left {
  color: #90929f;
  &:hover {
    cursor: pointer;
  }
}
</style>
