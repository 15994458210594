export const PROFILE_TABS = {
  GENERAL: 'General',
  SECURITY: 'Security',
  SESSIONS: 'Sessions',
  VERIFY: 'Verify',
  CURRENCY: 'Currency',
  ADD_SETTINGS: 'AddSettings'
}
//
// export const PROFILE_PAGES = {
//   PROFILE: 'PROFILE'
// }
export const ACTION_SESSION = {
  CURRENT: true,
  COMPLETED: false
}
