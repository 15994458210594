export default {
  namespaced: true,
  state: {
    compointStatuses: []
  },
  getters: {
    compointStatuses (state) {
      return state.compointStatuses
    }
  },
  mutations: {
    SOCKET_COMPOINT (state, [event, data]) {
      console.warn('SOCKET_COMPOINT--data', data)
      state.compointStatuses = data
    }
  },
  actions: {
    load (store, data) {
      store.commit('load', data)
    }
  }
}
