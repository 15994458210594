import { registrationApiService } from '@/common/services/apiServices'

export default {
  namespaced: true,
  state: {
    registrationPair: {
      login: '',
      password: ''
    }
  },
  getters: {
    getRegistartionPair: state => state.registrationPair
  },
  mutations: {
    setRegistationPair: (state, data) => {
      state.registrationPair = data
    }
  },
  actions: {
    async registerFast (store, { rToken, nick, avatar, currency }) {
      const refHash = store.rootGetters['user/refHash']
      return registrationApiService.registerFast(
        {
          rToken,
          nick,
          avatar,
          refHash,
          currency
        }
      ).then(({ login, password }) => {
        store.dispatch('successRegistration')
        store.commit('setRegistationPair', { login, password })
        store.dispatch('user/setRefHash', { hash: null }, { root: true })
      })
    },
    async registerEmail (store, { email, password, rToken, avatar, nick, login, currency }) {
      const refHash = store.rootGetters['user/refHash']

      return registrationApiService.registerEmail({ refHash, email, password, rToken, avatar, nick, login, currency })
        .then(() => {
          store.dispatch('successRegistration')
          store.dispatch('user/setRefHash', { hash: null }, { root: true })
        })
    },
    async sendEmail (store, { email, password, login }) {
      return registrationApiService.sendEmail(email, password, login)
    },
    async successRegistration (store) {
      store.dispatch('auth/successLogin', null, { root: true })
    }
  }
}
