<template>

  <div class="btn-repeat">
    <div class="otp-timer">
      <svg id="svg" width="36" height="36" version="1.1">
        <circle r="16" cx="18" cy="18" fill="transparent" stroke-dasharray="100" style="stroke-dashoffset: 0;"></circle>
        <circle ref="circle" id="bar" r="16" cx="18" cy="18" fill="transparent" stroke-dasharray="102"
                style="stroke-dashoffset: 0;"></circle>
      </svg>
      <span class="time" v-if="this.hide_zero && this.expire === 0"></span>
      <span class="time" v-else>{{this.expire}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['expire', 'expire_max', 'hide_zero'],
  data () {
    return {
      percent: 100
    }
  },
  computed: {
    ...mapGetters('user', {
      checkAuth: 'auth',
      balance: 'balance'
    })
  },
  watch: {
    expire: function (val, oldVal) {
      this.percent = ((this.expire / this.expire_max) * 100)
      this.$refs.circle.style['stroke-dashoffset'] = this.percent
    }
  },
  mounted: function () {
    this.$refs.circle.style['stroke-dashoffset'] = this.percent
  }
}
</script>

<style scoped>

  .btn-repeat {
    width: 36px;
    height: 36px;
    display: block;
    background: none;
    outline: none;
    border: none;
    padding: 0;
    transform: scale(2.4);
  }

  .otp-timer {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: relative;
    background: none;
    color: #333333;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 35px;
  }

  .otp-timer #svg {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  svg:not(:root) {
    overflow: hidden;
  }

  .otp-timer #svg circle {
    -webkit-transition: stroke-dashoffset 1s linear;
    transition: stroke-dashoffset 1s linear;
    stroke: #191a21;
    stroke-width: 4px;
  }

  .otp-timer .time {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    text-align: center;
    width: 36px;
    color: #FFFFFF;

    font-size: 16px;
  }

  .otp-timer #svg #bar {
    stroke: #ffe400;
  }
</style>
