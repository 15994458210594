<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col center-column">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
// import Menu from './Menu'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Index',
  components: {
    // Menu
  },
  watch: {},
  computed: {
    ...mapState('game', ['categories', 'providers'])
  },
  methods: {
    ...mapActions('game', ['getGames', 'getCategories', 'getProviders'])
  }
}
</script>

<style scoped lang="scss">

.menu {
  max-width: 300px;
}
.center-column {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.casino {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  &-list {
    padding-top: 25px;
  }
}

@media (max-width: 700px) {
  .casino {
    display: block;
  }
}
@media (min-width: 700px) {
  .casino-list {
    display: block;
    max-width: 350px;
    margin-left: -15px;
  }
}
</style>
