<template>
  <div class="preloader">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'DisbetPreloader'
}
</script>

<style lang="scss" scoped>
.preloader{
  width: 100%;
  height: 100%;
  min-width: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner{
  width: 50px;
  height: 50px;
  border-bottom-color: yellow;
  border-right-color: yellow;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;

  animation: rotate-animation 0.9s linear 0s infinite;
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
