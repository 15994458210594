import { bonusApiService } from '@/common/services/apiServices'

export default {
  namespaced: true,
  state: {
    registrationPair: {
      login: '',
      password: ''
    }
  },
  getters: {
    getRegistartionPair: state => state.registrationPair,
    getDacInfo (state, getters, rootState, rootGetters) {
      const dacAccounts = rootGetters['user/getActiveAccounts'].filter(acc => acc.currencyId === 'DAC')
      const availableBalance = dacAccounts.reduce((acc, item) => {
        if (item.currencyId === 'DAC') {
          if (item.typeId === 0) {
            acc.availableBalance = item.balance
          } else if (item.typeId === 1) {
            acc.blockedBalance += item.balance
          }
        }
        return acc
      }, { availableBalance: null, blockedBalance: 0 })
      return availableBalance
    }
  },
  mutations: {
    setRegistationPair: (state, data) => {
      state.registrationPair = data
    }
  },
  actions: {
    socket_bonus (store, [event, data]) {
      switch (event) {
        case 'deposit':
          store.dispatch('modals/openBonusDepositInfoModal', data, { root: true })
          break
      }
    },
    async getBonusLogs (store) {
      return bonusApiService.getBonusLogs()
    }
  }
}
