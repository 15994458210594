<template>
  <div class="direction">
    <div
      class="direction-item cursor-pointer"
      v-for="button in buttons"
      :key="button.key"
      @click="onButtonClick(button.key)"
      :class="button.key === activeKey ? 'active' : ''"
    >
      {{ button.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['buttons', 'onButtonClick', 'activeKey']
}
</script>
<style lang="scss" scoped>
.direction {
  display: inline-flex;
  background: #4a4b52;
  border-radius: 2rem;
  font-weight: bold;
  color: #bababa;
  &-item {
    padding: 3px 20px;
  }
}
.active {
  background: #9184b7;
  color: white;
  border-radius: 2rem;
  padding: 3px 20px;
}
</style>
