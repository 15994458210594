<template>
  <div>
  <DataTable v-if="transactions.length" class="table" responsiveLayout="scroll" :value="transactions" :scrollable="true" scrollHeight="flex">
    <Column  style="font-weight: 300;" :header="$t('cashbox.transaction.date')">
      <template #body="slotProps">
        <span>{{ createDate(slotProps.data.updatedAt) }}</span>
      </template>
    </Column>
    <Column class="amount" :header="$t('cashbox.transaction.amount')">
      <template #body="slotProps">
        <img :alt="transactions.amount" style="height: 16px; margin-right: 5px" :src="slotProps.data.currency.src">
        <span style="color: #fff; font-weight: 700">{{ slotProps.data.amount }}</span>
      </template>
    </Column>
    <Column style=" font-weight: 300;" :header="$t('cashbox.transaction.state')">
      <template #body="slotProps">
        <span :class="`state-${slotProps.data.stateId}`" style="font-weight: 300; text-transform: capitalize;">{{ slotProps.data.stateId }}</span>
      </template>
    </Column>
    <Column :header="$t('cashbox.transaction.trans')">
      <template #body="slotProps">
        <span class="transactions-more" @click="openTransactionDetailsModal(slotProps.data)" style="font-weight: 300">{{ $t('cashbox.transaction.detailed')}}</span>
      </template>
    </Column>
  </DataTable>
    <div class="data-null" v-if="transactions.length === 0">
      <p>{{ $t('main.table_data_null') }}</p>
    </div>
  </div>
</template>

<script>
import { PAYMENT_STATES } from '@/modules/cash/constants'
import dayjs from 'dayjs'
import { mapActions } from 'vuex'
export default {
  name: 'TransactionHistoryTable',
  props: ['transactions'],
  methods: {
    getState (stateId) {
      switch (stateId) {
        case PAYMENT_STATES.CREATED: return this.$t('cashbox.payment_states.created')
        case PAYMENT_STATES.COMPLETED: return this.$t('cashbox.payment_states.completed')
        case PAYMENT_STATES.PROCESSING: return this.$t('cashbox.payment_states.processing')
        case PAYMENT_STATES.CANCELED: return this.$t('cashbox.payment_states.canceled')
        case PAYMENT_STATES.REGISTERED: return this.$t('cashbox.payment_states.registered')
        case PAYMENT_STATES.REJECTED: return this.$t('cashbox.payment_states.rejected')
        case PAYMENT_STATES.ERROR: return this.$t('cashbox.payment_states.error')
        default: return ''
      }
    },
    ...mapActions('cash/withdraw', ['cancelWithdraw']),
    ...mapActions('modals', ['openTransactionDetailsModal']),
    createDate (date) {
      return dayjs(date).format('YYYY.MM.DD HH:mm:ss')
    }
  }
}
</script>

<style scoped lang="scss">
.data-null {
  width: 100%;
  height: 100%;
  text-align: center;
}
.data-null img {
  display: block;
  width: 120px;
  height: 120px;
  margin: auto;
}
.table {
  margin-right: -10px;
  margin-left: -14px;
  max-height: 591px;
  height: calc(90vh - 225px);;
  //overflow: scroll;
  border-radius: 15px 15px 0 0;
  width: 105.5%;
}

@media(max-width: 1200px) {
  .table {
    height: calc(90vh - 210px);
  }
}

@media (max-width: 576px) {
  .table {
    height: calc(100vh - 200px);
    overflow-x: hidden;
  }
}

::v-deep .p-datatable-thead>tr>th {
  background-color: #292C38 !important;
}

::v-deep .p-datatable-scrollable-header {
  border-radius: 15px 15px 0 0 !important;
}

::v-deep .p-datatable-scrollable-header-box {
  padding-right: 0 !important;
}

::v-deep .p-datatable-scrollable-body::-webkit-scrollbar {
  width: 0 !important;
}

::v-deep .p-datatable-tbody>tr>td {
  border: none !important;
}

::v-deep .p-datatable-tbody>tr>td {
  background-color: #1C1F29;
}

::v-deep .p-datatable .p-datatable-thead>tr>th {
  background-color: #292C38 !important;
}

::v-deep .p-column-title {
  color: #969696;
  font-weight: 300;
}

.state-1, .state-5{
  color: white;
}

.state-2{
  color: #BFFF38;
}

.state-3{
  color: #FFBB38;
}

.state-4, .state-6{
  color: #FF3E3E
}

.state-7{
  color: red;
}

.transactions-more {
  font-weight: 300;
  cursor: pointer;
  transition: .3s ease-in-out all;
}

.transactions-more:hover {
  color: #fff;
}

</style>
