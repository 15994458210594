<template>
  <div></div>
</template>

<script>

import ModalAuth from '@/modules/auth/components/Auth'
import ModalCash from '@/modules/cash/components/Modal/ModalCash'
import VipClub from '@/modules/vipClub/VipClub.vue'
import ModalNewPassword from '@/modules/auth/components/newPassword/Modal/ModalNewPassword'

export default {
  name: 'DisbetAppModals',
  methods: {
    defineShowModals () {
      const to = this.$router.history.current
      if (to.query.modal === 'auth') {
        this.$modal.hide('modal')
        this.$modal.show(
          ModalAuth,
          {},
          {
            name: 'modal',
            clickToClose: false,
            adaptive: true,
            height: '100%',
            width: '100%'
          }
        )
      } else if (to.query.modal === 'cash') {
        this.$modal.hide('modal')
        this.$modal.show(
          ModalCash,
          {},
          {
            name: 'modal',
            clickToClose: false,
            adaptive: true,
            height: '100%',
            width: '100%'
          }
        )
      } else if (to.query.modal === 'vipClub') {
        this.$modal.hide('modal')
        this.$modal.show(
          VipClub,
          {},
          {
            name: 'modal',
            clickToClose: false,
            adaptive: true,
            height: '100%',
            width: '100%'
          }
        )
      } else if (to.query.modal === 'resetPassword') {
        this.$modal.hide('modal')
        this.$modal.show(
          ModalNewPassword,
          {},
          {
            name: 'modal',
            clickToClose: false,
            adaptive: true,
            height: '100%',
            width: '100%'
          }
        )
      } else {
        this.$modal.hide('modal')
      }
    }
  },
  watch: {
    '$route' () {
      this.defineShowModals()
    }
  },
  created () {
    this.defineShowModals()
  }
}
</script>

<style lang="scss" scoped>

</style>
