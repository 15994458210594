<template>
  <div class="card-promo">
    <div class="card-body">
      <p class="card-body__title text-uppercase">бонус</p>
      <p class="card-body__subtitle">180%</p>
      <Button class="p-button p-component card-button">Получить</Button>
    </div>
  </div>
</template>

<script>
export default {
  props: {}
}
</script>

<style lang="scss" scoped>
.card {
  &-promo {
    color: white;
    border: none;
    border-radius: 25px;
    height: 261px;
    overflow: hidden;
    background: rgb(142,67,154);
    background: radial-gradient(circle, rgba(142,67,154,1) 0%, rgba(46,18,125,1) 100%);
    padding: 44px 28px;
  }

  &-button {
    border-radius: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  &-body {
    &__title {
      font-size: 32px;
      font-weight: bold;
      margin: 0;
      line-height: 14px;

      @media (max-width: 767px) {
        font-size: 24px;
      }

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }
    &__subtitle {
      font-size: 64px;
      font-weight: bold;
      line-height: 14px;
      margin-top: 30px;

      @media (max-width: 767px) {
        font-size: 48px;
      }
    }
  }
}
</style>
