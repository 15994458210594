import { render, staticRenderFns } from "./E404.vue?vue&type=template&id=8a4f13cc&"
import script from "./E404.vue?vue&type=script&lang=js&"
export * from "./E404.vue?vue&type=script&lang=js&"
import style0 from "./E404.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports