<template>
  <div class="content-wrapper">
    <DisbetPreloader v-if="getFetchStatus === 'FETCH'" />
    <div v-else-if="getErrorStatus === true">Internal Error Please try again latter...</div>
    <slot v-else></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { DisbetPreloader } from '@/common/components'

export default {
  name: 'ConvertedWrapper',
  components: { DisbetPreloader },
  computed: {
    ...mapGetters('cash/converted', ['getErrorStatus', 'getFetchStatus'])
  }
}
</script>

<style scoped lang="scss">

.content-wrapper {
  width: 100%;
}

.active {
  border-radius: 1px;
}
.active:hover {
  border-color: #b19df7;
  cursor: pointer;
}
</style>
