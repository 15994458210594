import { bonusTypes } from '@/utils/constants'

export const tr = {
  navigations: {
    main: 'Ev',
    casino: 'Kumarhane',
    livegames: 'Canlı oyunlar',
    page_null: 'Ah! Aradığınız sayfa bulunamadı!'
  },
  errors: {
    message_tf: 'Mesaj hatası',
    base: 'hata'
  },
  avatar_modal: {
    edit_avatar: 'Avatarınızı düzenleyin',
    default_avatar: 'Varsayılan avatar',
    invalid_file_type: 'Geçersiz dosya türü',
    max_scale: 'Maks ölçek',
    min_scale: 'Minimum ölçek',
    choose_image: 'Avatar Yükle',
    button_confirm: 'Onayla',
    change: 'Değiştir'
  },
  header: {
    online: 'Çevrimiçi',
    auth: 'Giriş yap',
    cashback: 'Cashback',
    cashbox: 'Cüzdan',
    game_mode: 'Oyun Modları',
    search: 'Oyun | sağlayıcı | Kategori'
  },
  avatar: {
    select_avatar: 'Avatar seç',
    change_avatar: 'Avatar değişikliği'
  },
  main: {
    table_data_null: 'Ey! Henüz veri yok!',
    footer: {
      page_rates: 'Ana Sayfa',
      page_chat: 'Sohbet',
      page_slots: 'Yuvalar',
      page_live: 'Bayi',
      page_bonus: 'Bonus',
      page_more: 'Diğer',
      promotion: 'Promosyon',
      referral: 'Ortak',
      support: 'Yardım merkezi'
    },
    slider: {
      all: 'Herşey',
      games: 'Oyunlar',
      slots: 'Yuvalar',
      big_win: 'Son zamanlardaki en büyük galibiyet'
    }
  },
  tooltip: {
    total: 'Toplam',
    unlocked: 'Engellendi',
    unlock_process: 'Kilit açma işlemi',
    unlock_amount: 'Kilit Açma Miktarı = '
  },
  authentication: {
    // i_have_account: 'У меня уже есть учетная запись',
    forgot_password: 'Parolanızı mı unuttunuz?',
    register_title: 'Üye olmak',
    // register_account: 'Зарегистрироваться',
    register_another_resources: 'ile doğrudan giriş yapın',
    auth_another_resources: 'ile doğrudan giriş yapın',
    enter_email_valid: 'Lütfen geçerli bir e-posta girin: user@example.com',
    enter_email: 'E-posta girin',
    enter_login: 'E-posta adres',
    // enter_login_minLength: 'минимум 5 символов',
    enter_password: 'Parola',
    enter_password_minLength: 'En az 6 karakter',
    enter_login_minLength: 'En az 6 karakter',
    enter_input: 'Giriş',
    name_correct: 'Geçerli bir ad girin',
    password_has_uppercase: 'Şifre büyük harf içermelidir',
    password_has_lowercase: 'Şifre küçük harf içermelidir',
    password_has_number: 'Parola bir sayı içermelidir',
    back_auth: 'Girişe geri dön',
    new_password: 'Yeni şifreyi girin',
    enter_new_password: 'Yeni şifreyi tekrar girin',
    recovery_password_title: 'Hesap şifresini sıfırla',
    remember_passord: 'Şifreyi hatırla!',
    login: 'Kayıt olmak',
    auth: 'Kayıt olmak',
    create_account: 'Üye olmak',
    recovery_password: 'Şifreyi yenile',
    you_login: 'Giriş yapmak',
    send: 'Göndermek',
    save_file: 'Dosyayı kaydet',
    send_to_email: 'Veya e-postanıza gönderin',
    login_msg: {
      success: 'Başarıyla',
      email_incorrect: 'lütfen geçerli eposta adresini giriniz'
    },
    register: {
      fast: '1 tıklamayla',
      email: 'E-posta'
    },
    fast_register: {
      welcome: 'Disapp hoş geldiniz',
      username: 'Takma ad',
      email: 'E-posta',
      choice_icon: 'Varsayılan bir avatar seçin',
      submit: 'Onaylamak',
      register_success: 'Başarı',
      dont_forget: 'Kullanıcı adınızı ve şifrenizi kaydetmeyi unutmayın'
    },
    bonus: 'Oyunlara yeni bir şekilde bakın',
    confirmation_agreement: {
      text_1: 'Katılıyorum',
      text_2: 'Kullanıcı Sözleşmesi',
      text_3: ', ve en az 18 yaşında olduğumu onaylayın!'
    }
  },
  bonus: {
    data_null: 'Ah... Henüz kullanılabilir bonus yok, ancak yakında olacaklar!',
    promo: {
      activate: 'Etkinleştir'
    }
  },
  bonusTypes: {
    [bonusTypes.FIRST_DEPOSIT]: 'İlk depozito',
    [bonusTypes.SECOND_DEPOSIT]: 'İkinci depozito',
    [bonusTypes.THIRD_DEPOSIT]: 'Üçüncü depozito',
    [bonusTypes.AIRDROP]: 'Airdrop',
    [bonusTypes.DEPOSIT]: 'Depozito',
    [bonusTypes.REGISTER]: 'Kayıt',
    [bonusTypes.SOCIAL]: 'Sosyal ağlar',
    [bonusTypes.REFERRAL]: 'Referans programı',
    [bonusTypes.PROMO_CODE]: 'Promosyon kodu'
  },
  cash: {
    currency: {
      active_currency_list: 'Aktif para birimleri listesi',
      manage: 'Listeyi yönet',
      price: 'FİYAT:',
      check: 'Görüntüle ',
      copy: 'Başarılı kopya',
      choise_currency: 'Para birimi seçimi',
      choise_pay_system: 'Para birimi seçimi',
      only_active: 'Sadece aktif',
      transaction: 'Işlemler',
      important: 'FARK ETME:',
      important_text: ' Sadece gönder ',
      important_text_last:
        'bu mevduat adresine. 2 ağ onayından sonra jetonlar otomatik olarak yatırılacaktır.',
      noty: 'BİLDİRİM:',
      noty_text:
        ' Akıllı sözleşme adresleri desteklenmez(Bize ulaşın).'
    },
    dac: {
      panel: 'DAC Paneli',
      what_is_dpd: 'DAC nedir?',
      this_dpd: 'DAC (Disapp Coin), disapp.net tarafından başlatılan özel bir para birimidir. Oyun oynayabilir, airdrop başlatabilir ve diğer para birimleriyle takas edebilirsiniz.',
      how_get_dpd: 'DAC nasıl edinilir?',
      get_dpd: 'Depozito bonusu ve diğer etkinlikler yoluyla DAC alabilirsiniz. Ayrıca mevcut DAC bakiyeniz için diğer para birimlerini',
      get_dpd_last: 'cüzdanda.',
      how_ulock_dpd: 'DAC nasıl açılır?',
      ulock_dpd: 'Bonus yoluyla kazanılan DACnin kilidi, bahisle orantılı olarak açılmalıdır.',
      other_games: 'Farklı oyunların farklı bahisleri vardır.',
      how_exchange: 'DAC başka bir para birimiyle değiştirebilir miyim?',
      can_exchange: 'Elbette! En ilginç şey, 1 DAC maliyetinin 1 USD fiyatına sabitlenmesi ve bunu yapabilirsiniz',
      can_exchange_last: ' DAC başka bir para birimine.',
      how_special_dpd: 'DAC hakkında özel olan nedir?',
      // special_dpd: 'You can enjoy up to 10% annualized returns when you store your DAC in ',
      // special_dpd_last: '. So enjoy your DAC storage!',
      unlocked: 'Mevcut',
      history: 'Alınan DAC geçmişi',
      total: 'Toplam',
      unlock_process: 'Kilit açma işlemi',
      lock: 'Kilitli',
      unlock_amount: 'kilit açma miktarı = '
    }
  },
  games: {
    list: {
      MW: 'Çok Kazanç',
      TWG: 'En Çok Kazanan Oyunlar',
      TRN: 'Şu Anda Trend',
      RP: 'Son Oynanan',
      BFC: 'Casino dan En İyisi',
      FBIS: 'Özellik Satın Alma Yuvaları',
      RLG: 'Önerilen Canlı Oyunlar',
      TCG: 'Trenging kumarhane oyunları',
      HCG: 'Sıcak kumarhane oyunları',
      BLG: 'En İyi Canlı Oyunlar',
      TLG: 'Trengin canlı oyunlar',
      HLG: 'Sıcak canlı oyunlar',
      won: 'Kazandı',
      in: '',
      profit: 'Kâr'
    },
    casino: {
      more_games: 'Daha fazla yükle',
      search: 'Bulundu',
      game_total: 'Oyunlar',
      all_provider: 'Tüm sağlayıcı',
      providers: 'Sağlayıcılar',
      categories: 'Kategoriler'
    },
    play: {
      info: 'Üçüncü taraf bir oyuna girme. Seçilen para biriminin bakiyesi {currency} olarak görüntülenecek ve oyun sırasında para birimini değiştirirseniz oyun yenilenecek ve yeniden başlayacaktır.',
      start: 'Şimdi oyna'
    }
  },
  policy: {
    name: 'Privacy policy',
    1: 'Please read this Privacy Policy carefully before using https://disapp.net',
    2: 'We provide access to various games on our platform. By using our services, you trust us with your information. We understand that this is a big responsibility and we make every effort to protect your data and enable you to manage it.',
    3: 'This Privacy Policy describes what information we collect, for what purpose and how you can update, delete your data and manage it.\n' +
      'All information that we collect about users is available in your profile settings. You can access the personal information collected by disapp.net by clicking on the "Settings" link on the website or by contacting us at the email address support@disapp.net.\n' +
      'If you want to delete all information about yourself, as well as your profile, please write to us by mail: support@disapp.net',
    4: 'Information we collect',
    5: '- Name',
    6: '- E-mail address',
    7: '- IP-address',
    8: '- Number (s) of wallets from which there were deposits and withdrawals',
    9: '- Если Вы создаете аккаунт через социальные сети (Telegram, Google, Connect Wallet), then, accordingly, we will store your name, profile photo, wallet number, link to the page on social networks.',
    10: 'Each player who creates an account has a username and password. The password is stored in encrypted form.',
    11: 'How we use information received from players',
    12: 'By becoming a player or otherwise providing disapp.net with personal information, you agree that we collect and use your personal information to provide the services.',
    13: 'You agree to provide a telephone number and / or email address to a third party for marketing purposes.',
    14: 'You agree to provide the game id, your player’s name, bet, winning odds and winnings for publication on our social networks.',
    15: 'You agree that we have the right to disclose your personal information, but only for the purpose and in cases where such a requirement has been put forward by government bodies, in the manner prescribed by applicable law.',
    16: 'We receive information from the player so that the player can:',
    17: '- use our services, in particular, play games and place bets;',
    18: '- write in the chat, communicate with other players;',
    19: '- subscribe to the weekly newsletter',
    20: '- create, manage, close an account.',
    21: 'In the profile settings on our website, a player can:',
    22: '- make your account anonymous;',
    23: '- hide the amount of bets of your own profile;',
    24: '- hide the history of games on the site.',
    25: 'We receive information from the player so that we can:',
    26: '- identify you as the player for safety and compliance with our legal obligations;',
    27: '- maintain the player account;',
    28: '- check the accuracy of the information provided by the player;',
    29: '- receive questions and comments from the player in order to respond to them and improve our services;',
    30: '- periodically send written messages about important changes in the service, technical update issues, etc.;',
    31: '- study the suspicion of illegal, fraudulent activities related to our services;',
    32: '- report to the regulatory authorities on the commission of crimes or suspected crimes, including money laundering or fraud;',
    33: '- increase the safety and reliability of our services;',
    34: '- perform other tasks that are necessary to fulfill contractual obligations to the player.',
    35: 'Marketing',
    36: 'From time to time, we may use your personal information to inform you about our products or services, or about promotions that, in our opinion, may be interesting or useful to you. We can do this by email, SMS or phone or mail. If you no longer want to receive marketing or promotional materials from Play2x.com in general, or in any specific form, you can contact us at support@play2x.com at any time with your request. We will respond and perform your request as soon as this will be possible.',
    37: 'Access to personal information',
    38: 'You can access the personal information collected by disapp.net by clicking on the “Settings” link on the website or by contacting us at the email address support@disapp.net, we will provide you with access to your personal information as necessary, if possible, and within a reasonable period of time. If we deny access for such reasons, we will notify you in writing of the denial, the reasons and complaint mechanisms that are available to you.',
    39: 'Information management',
    40: 'We will take all reasonable measures to ensure the safety of the collected, used or disclosed personal information about the player.',
    41: 'Доступ к такой информации могут иметь только уполномоченные лица.',
    42: 'Disbapp.net уничтожит персональную информацию, которой мы владеем, после того, как в ее использовании, хранении или раскрытии больше не будет надобности, но не скорее 5 лет после ее использования.',
    43: 'Management of this information should be in accordance with applicable law.',
    44: 'Security',
    45: 'You guarantee that you use all possible methods to preserve the information that you transmit to us.',
    46: 'You are responsible for the security and access to your own computer, mobile device or any other device used to access the site.',
    47: 'You are solely responsible for maintaining the confidentiality of your username, password, and any other account information.',
    48: 'We will take all reasonable steps to protect your personal information from unauthorized use, loss and unauthorized access, alteration and disclosure, including using password-protected systems, databases and Secure Socket Layer (SSL) technology.',
    49: 'disapp.net employees, agents and contractors are required to maintain the confidentiality of player personal information.',
    50: 'Changes to this policy',
    51: 'We change this Privacy Policy from time to time, but do not limit your rights described in it without your explicit consent. We always indicate the date of publication of the latest changes and provide access to previous versions. If the changes are significant, we will make a more noticeable message (in particular, we inform you about changes in the Privacy Policy of some services by email).',
    52: 'Complaints and applicable law',
    53: 'In case of disputes and disagreements, our decision is final and you fully agree with him. All disputes and disagreements arising out of or in connection with this policy are resolved through negotiations. If it is not possible to reach an agreement through negotiations, disputes, disagreements and claims arising from this policy shall be resolved in accordance with the applicable laws of the Netherlands Antilles. If you have any questions, requests for access, or complaints related to the processing, you can contact us through the chat support chat or e-mail: support@disapp.net'
  },
  footer: {
    install_disbet_app: 'Disapp Uygulamasını Yükle',
    responsible_gaming: 'Sorumlu oyun',
    privacy_policy: 'Gizlilik Politikası',
    helpdesk: 'Yardım Merkezi',
    term_of_use: 'Kullanım Şartları',
    support: 'Müşteri desteği',
    jobs: 'Meslekler'
  },
  cashbox: {
    header: 'Cüzdan',
    history_transact: 'Işlemler',
    payment_states: {
      created: 'Oluşturuldu',
      completed: 'Başarı',
      processing: 'Beklemek',
      canceled: 'İptal edildi',
      registered: 'Kayıtlı',
      rejected: 'İptal edildi',
      error: 'Hata'
    },
    menu: {
      replenishment_amount: 'Miktarı artırmak',
      withdrawal_amount: 'Para çekme miktarı',
      comission: 'Ücret',
      withdraw_currency: 'Para Birimi Çekin',
      deposite: 'Tutmak',
      deposite_pay: 'Ödemek',
      withdraw: 'Geri çekilmek',
      withdraw_order: 'Onaylamak',
      transactions: 'Depozito',
      choice: 'Swap',
      choice_start: 'Şimdi değiştir',
      send_deposit: 'Yatırım',
      withdraw_deposit: 'Yatırımı geri çek',
      inserest_rate: 'Yıllık faiz oranı',
      send: 'Göndermek',
      receive: 'Almak',
      warning_withdraw:
        'Güvenlik nedeniyle, büyük veya şüpheli para çekme, denetim süreci için 1-6 saat sürebilir. Sabrınız için teşekkür ederiz!'
    },
    transaction: {
      header: 'Işlemler',
      deposites: 'Mevduat',
      withdraws: 'Para çekme',
      date: 'Tarih',
      detailed: 'Detaylar için tıklayın',
      state: 'Belirtmek',
      amount: 'Miktar',
      trans: 'Işlem',
      awaiting: 'Onay Bekleniyor',
      canceled: 'İşlem iptal edildi',
      completed: 'Tamamlanmış',
      arrived: 'Disapp hesabınıza kripto para birimi geldi. Cüzdanınızdaki bakiyeyi kontrol edin.',
      send: 'Kripto, belirtilen kripto cüzdanına gönderildi. Makbuz bekleyin',
      confirmations: 'Onaylar',
      network: 'Ağ',
      address: 'Adres',
      head_detailed: 'Detaylandırma',
      help: 'Herhangi bir sorununuz varsa, lütfen iletişime geçin',
      chat: 'destek',
      cancel: 'İptal etmek'
    },
    swap: {
      you_send: 'Sen ver',
      you_get: 'Yaklaşık olarak alacaksınız',
      time: 'Tahmini süresi',
      fee: 'Takas ücreti',
      instant: 'Aniden'
    },
    monetary_assets: {
      assets: 'Varlıklar',
      history: 'Öykü',
      receive: 'Almak',
      invested: 'Yatırım',
      created: 'Oluşturuldu'
    },
    walletModal: {
      currencies: 'Para birimleri',
      active_balances: 'Aktif bakiyeler',
      currency_balances: 'Döviz bakiyeleri',
      cryptocurrencies: 'Kripto para',
      button_disabled: 'Devre dışı bırakmak',
      button_add: 'Ekle'
    },
    select_payment: 'Bir ödeme sistemi seçin',
    address_for_withdraw: 'Adresi Çekme',
    address_payment: 'Mevduat Adresi',
    address_network: 'Bir ağ seç',
    minimum_deposit: 'Min.',
    comment: 'Yorum',
    required: '(Gerekli)',
    address_crypto_payment: 'Belirli para birimine göre dikkatlice doldurun',
    help: 'Sorun olması durumunda',
    support: 'müşteri desteği ',
    deposite: 'Depozito',
    choose_currency: 'Mevduat Para Birimi',
    balance: 'Denge',
    more_deposite: 'yada daha fazla',
    bonus: {
      first: 'İlk para yatırma bonusu',
      more_deposite: 'yada daha fazla',
      name: 'Bonus',
      more: 'Daha',
      good_game: 'İyi oyunlar ve büyük kazançlar.',
      dep: 'Oooh... Para hesaba geldi'
    }
  },
  helpdesk: {
    open_chat: 'Açık sohbet',
    time_info_min: 'Ortalama yanıt süresi 10 dk.',
    time_info_max: 'Ortalama yanıt süresi 60 dk.',
    support_live: 'Destek (CANLI)',
    support_email: 'Destek (E-POSTA)',
    header: 'Operatörler 7/24 çalışır',
    faq: {
      name: {
        1: 'Can I register on your website?',
        2: 'What if I forget my password?',
        3: 'I have lost my mobile phone. How do I reset my Google authenticator?',
        4: 'Can I change my username or registered email address?',
        5: 'How do I become a VIP?',
        6: 'How to deposit?',
        7: 'How to withdraw?',
        8: 'Minimum Withdraw amount?',
        9: 'How long does it take to deposit and withdraw?',
        10: 'Where do transaction confirmations come from?',
        11: 'How long does it take to confirm a transaction?',
        12: 'Is your game fair and just?',
        13: 'What should I do if my game hangs or there is a problem?'
      },
      text: {
        1: 'You must be at least 18 years old or reach the age of majority under your jurisdiction. You must be permitted to play online games by the laws applicable to you. For more information, please read our terms and conditions. Games might be addictive, and players are advised to be self-control.',
        2: 'If you forget your password, you could reset it within 15 seconds through our “Forget password” link. After applying for password reset, please follow the instructions in the email we send to you to reset your password.',
        3: 'If you want to remove your Google authenticator 2FA, please contact us. After we receive your application, for the safety of your account, you are required to answer several security questions correctly in order to remove the 2FA.',
        4: 'If you insist on changing your username and/or registered email, you could reset it within 15 seconds through our “Reset email” link.',
        5: 'The exclusive VIP club is by invitation only. After you embark on your journey of the game, you will be notified of your VIP status by email soon.',
        6: ' Find the wallet page, click the deposit page, copy the wallet address, or scan QR code for payment. Quickly deposit and withdrawal directly from the cooperative wallet.',
        7: 'Find the wallet page, open the withdraw page, enter the address of the wallet you need to withdraw and the amount of cryptocurrency (pay attention to the fee). After clicking the confirm button, the cryptocurrency will be transferred to your wallet (pay attention to the corresponding cryptocurrency).',
        8: 'Because the value of each cryptocurrency is different, the minimum Withdraw amount is also different',
        9: 'Each transaction on the blockchain requires several cycles to confirm the transfer has been recorded successfully. Generally speaking, each transaction requires 5-10 minutes before it can be confirmed by the blockchain network. If you encounter any problem during deposit or Withdraw, you can visit www.blockchain.info to check your transaction, or contact technical support.',
        10: 'All confirmation information comes from the wallet supplier, the blockchain and miners.',
        11: 'It depends on the blockchain and your transfer fee. It might take 10 minutes or several hours.',
        12: 'We are based on the Ethereum smart contract. All data and core logic on the blockchain are transparent, and manipulation is not possible. Every single transaction on the blockchain can be tracked on etherscan. The fairness, openness, and justness of us gaming platform are guaranteed.',
        13: 'If you encounter any technical problem while playing our games, please try to refresh the game. Normally it will work after refresh. Should the problem persist, please contact us.'
      }
    },
    terms: {
      name: 'Terms of use',
      title: {
        0: 'Definitions',
        1: 'General',
        2: 'Binding Declarations',
        3: 'Restricted Territories',
        4: 'General Betting Rules',
        5: 'Bonuses and Promotions',
        6: 'Live Chat',
        7: 'Limitation of Liability',
        8: 'Breaches, Penalties and Termination'
      },
      text_0_1: 'disapp.net is referred to as \'we\' or \'us\'',
      text_0_2: 'The Player is referred to as "you" or \'the Player\'',
      text_0_3: 'The Website\' means disapp.net through desktop, mobile or other platforms utilised by the Player',
      text_1_1:
        'These User Agreement apply to the usage of games accessible through disapp.net',
      text_1_2:
        'These User Agreement come into force as soon as you complete the registration process, which includes checking the box accepting these User Agreement and successfully creating an account. By using any part of the Website following account creation, you agree to these User Agreement.',
      text_1_3:
        'You must read these User Agreement carefully in their entirety before creating an account. If you do not agree with any provision of these User Agreement, you must not create an account or continue to use the Website.',
      text_1_4:
        'We are entitled to make amendments to these User Agreement at any time and without advanced notice. If we make such amendments, we may take appropriate steps to bring such changes to your attention (such as by email or placing a notice on a prominent position on the Website, together with the amended User Agreement) but it shall be your sole responsibility to check for any amendments, updates and/or modifications. Your continued use of disapp.net services and Website after any such amendment to the User Agreement will be deemed as your acceptance and agreement to be bound by such amendments, updates and/or modifications.',
      text_1_5:
        'These User Agreement may be published in several languages for informational purposes and ease of access by players. The English version is the only legal basis of the relationship between you and us and in the case of any discrepancy with respect to a translation of any kind, the English version of these User Agreement shall prevail.',
      text_2_1:
        'By agreeing to be bound by these User Agreement, you also agree to be bound by the disapp.net Rules and Privacy Policy that are hereby incorporated by reference into these User Agreement. In the event of any inconsistency, these User Agreement will prevail. You hereby represent and warrant that:',
      text_2_1_1: 'You are over (a) 18 or (b) such other legal age or age of majority as determined by any laws which are applicable to you, whichever age is greater;',
      text_2_1_2: 'You have full capacity to enter into a legally binding agreement with us and you are not restricted by any form of limited legal capacity;',
      text_2_1_3: 'All information that you provide to us during the term of validity of this agreement is true, complete, correct, and that you shall immediately notify us of any change of such information;',
      text_2_1_4: 'You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive from us;',
      text_2_1_5: 'You understand that by using our services you take the risk of losing money deposited into your Member Account and accept that you are fully and solely responsible for any such loss;',
      text_2_1_6: 'You are permitted in the jurisdiction in which you are located to use online casino services;',
      text_2_1_7: 'In relation to deposits and Withdraws of funds into and from your Member Account, you shall only use Ethereum that are valid and lawfully belong to you;',
      text_2_1_8: 'You understand that the value of Ethereum can change dramatically depending on the market value;',
      text_2_1_9: 'The computer software, the computer graphics, the Websites and the user interface that we make available to you is owned by disapp.net or its associates and is protected by copyright laws. You may only use the software for your own personal, recreational uses in accordance with all rules, User Agreement we have established and in accordance with all applicable laws, rules and regulations;',
      text_2_1_10: 'You understand that Ethereum is not considered a legal currency or tender and as such on the Website they are treated as virtual funds with no intrinsic value.',
      text_2_1_11: 'You affirm that you are not an officer, director, employee, consultant or agent of disapp.net or working for any company related to disapp.net, or a relative or spouse of any of the foregoing;',
      text_2_1_12: 'You are not diagnosed or classified as a compulsive or problem gambler. We are not accountable if such problem gambling arises whilst using our services, but will endeavour to inform of relevant assistance available. We reserve the right to implement cool off periods if we believe such actions will be of benefit.',
      text_2_1_13: 'You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited techniques, including but not limited to fraudulent transaction detection, automated registration and signup, gameplay and screen capture techniques. These steps may include, but are not limited to, examination of Players device properties, detection of geo-location and IP masking, transactions and blockchain analysis;',
      text_2_1_14: 'You accept our right to terminate and/or change any games or events being offered on the Website, and to refuse and/or limit bets.',
      text_2_1_15: 'You accept that we have the right to ban/block multiple accounts and freely control the assets in such accounts.',
      text_2_1_16: 'You are aware of possible errors or incompleteness in the software, you agree to refrain from taking advantage of them. Moreover, you agrees to report any error or incompleteness immediately to disapp.net. Should you fail to fulfil the obligations stated in this clause, disapp.net has a right to full compensation for all costs related to the error or incompleteness, including any costs incurred in association with the respective error/incompleteness and the failed notification by user.',
      text_2_1_17: 'You are aware of that disapp.net have the right to carry out “KYC” (Know Your Customer) verification procedures. The access to your user account may be blocked or closed if we determine that you have supplied false or misleading information.',
      text_2_2:
        'We reserves the right to declare a wager void partially or in full if disapp.net, at its own discretion, would deem it obvious that any of the following circumstances have occurred:',
      text_2_2_1:
        'You, or people associated with you may directly or indirectly influence the outcome of an event, to obtain an unlawful advantage.',
      text_2_2_2:
        'You and or people associated with you are directly or indirectly avoiding the rules of disapp.net',
      text_2_2_3:
        'The result of an event has been directly or indirectly affected by criminal activity.',
      text_2_2_4:
        'Wagers have been placed that would not have been accepted otherwise, but that were accepted during periods when the website have been affected by technical problems.',
      text_2_2_5:
        'Due to an error, such as a mistake, vulnerabilities, technical error, force majeure or otherwise, wagers have been offered, placed and or accepted due to this error.',
      text_2_2_6:
        'If a player\'s deposit fee is too low and is flagged by blockchain or similar site as “not enough fee to relay” disapp.net reserve the right to confiscate the winnings if disapp.net at their own discretion deem the transaction and behavior of the player to be fraudulent in nature.',
      text_3_1:
        'Blacklisted Territories: China, Costa Rica, Netherlands, Dutch Caribbean Islands, Curacao, France, United States and/or any other restricted by law country or state. Note that it is strictly forbidden to play on disapp.net games in blacklisted countries mentioned above.your personal data for the purpose of executing their duties and providing you with the best possible assistance and service. You hereby consent to such disclosures.',
      text_4_1:
        'A bet can only be placed by a registered account holder.',
      text_4_2:
        'A bet can only be placed over the internet.',
      text_4_3:
        'You can only place a bet if you have sufficient balance in your account with disapp.net',
      text_4_4:
        'The bet, once concluded, will be governed by the version of the User Agreement valid and available on the Website at the time of the bet being accepted.',
      text_4_5:
        'Any payout of a winning bet is credited to your account, consisting of the stake multiplied by the odds at which the bet was placed.',
      text_4_6:
        'disapp.net reserves the right to adjust a bet payout credited to a disapp.net account if it is determined by disapp.net in its sole discretion that such a payout has been credited due to an error.',
      text_4_7:
        'The bet, once concluded, will be governed by the version of the User Agreement valid and available on the Website at the time of the bet being accepted.',
      text_4_8:
        'The list of all the bets, their status and details are available to you on the Website.',
      text_4_9:
        'When you place a bet you acknowledge that you have read and understood in full all of these User Agreement regarding the bet as stated on the Website.',
      text_4_10:
        'disapp.net manages your account, calculates the available funds, the pending funds, the betting funds as well as the amount of winnings. Unless proven otherwise, these amounts are considered as final and are deemed to be accurate.',
      text_4_11:
        'You are fully responsible for the bets placed.',
      text_4_12:
        'Winnings will be paid into your account after the final result is confirmed.',
      text_5_1:
        'disapp.net reserves the right to cancel any promotion, bonus or bonus program (including, but not limited to top-up rewards, invite friends to reward bonuses and loyalty programs) with immediate effect if we believe the bonus has been set up incorrectly or is being abused, and if said bonus has been paid out, we reserve the right to decline any Withdraw request and to deduct such amount from your account. Whether or not a bonus is deemed to be set up incorrectly or abused shall be determined solely by disapp.net',
      text_5_2:
        'If you use a Deposit Bonus, no Withdraw of your original deposit will be accepted before you have reached the requirements stipulated under the User Agreement of the Deposit Bonus.',
      text_5_3:
        'disapp.net reserves the right to adjust a bet payout credited to a disapp.net account if it is determined by disapp.net in its sole discretion that such a payout has been credited due to an error.',
      text_5_4:
        'All disapp.net offers are intended for recreational players and disapp.net may in its sole discretion limit the eligibility of customers to participate in all or part of any promotion.',
      text_5_5: 'disapp.net reserves the right to amend, cancel, reclaim or refuse any promotion at its own discretion.',
      text_5_6: 'Bonuses can only be received once per person/account, family, household, address, e-mail address, IP addresses and environments where computers are shared (university, fraternity, school, public library, workplace, etc.). The Operator reserves the right to close your account and confiscate any existing funds if evidence of abuse/fraud is found.',
      text_5_7:
        'You acknowledge and understand that separate User Agreement exist with respect to promotions, bonuses and special offers, and are in addition to these User Agreement. These User Agreement are set forth in the respective content page on this website, or have been made available to you personally, as the case may be. In the event of a conflict between the provisions of such promotions, bonuses and special offers, and the provisions of these User Agreement, the provisions of such promotions, bonuses and special offers will prevail.',
      text_5_8:
        'We may insist that you bet a certain amount of your own deposit before you can bet with any free/bonus funds we credit to your account.',
      text_5_9:
        'You accept that certain promotions may be subject to Withdraw restrictions and/or requirements which need to be met before funds credited under the promotion can be withdrawn. Such terms shall be duly published and made available as part of the promotion. If you opt to make a Withdraw before the applicable wagering requirements are fulfilled, we will deduct the whole bonus amount as well as any winnings connected with the use of the bonus amounts before approving any Withdraw.',
      text_6_1:
        'As part of your use of the Website we may provide you with a live chat facility, which is moderated by us and subject to controls. We reserve the right to review the chat and to keep a record of all statements made on the facility. Your use of the chat facility should be for recreational and socialising purposes.',
      text_6_2: 'We have the right to remove the chat room functionality or immediately terminate your Member Account and refund your account balance if you:',
      text_6_2_a: '(a) make any statements that are sexually explicit or grossly offensive, including expressions of bigotry, racism, hatred or profanity;',
      text_6_2_b: '(b) make statements that are abusive, defamatory or harassing or insulting;',
      text_6_2_c: '(c) use the chat facility to advertise, promote or otherwise relate to any other online entities;',
      text_6_2_d: '(d) make statements about disapp.net, or any other Internet site(s) connected to the Website that are untrue and/or malicious and/or damaging to disapp.net;',
      text_6_2_e: '(e) user the chat facility to collude, engage in unlawful conduct or encourage conduct we deem seriously inappropriate. Any suspicious chats will be reported to the competent authority.',
      text_6_3: 'Live Chat is used as a form of communication between us and you and should not be copied or shared with any forums or third parties.',
      text_7_1:
        'You enter the Website and participate in the Games at your own risk. The Websites and the Games are provided without any warranty whatsoever, whether expressed or implied.',
      text_7_2:
        'Without prejudice to the generality of the preceding provision, we, our directors, employees, partners, service providers.',
      text_7_3:
        'Do not warrant that the software, Games and the Websites are fit for their purpose.',
      text_7_4:
        'Do not warrant that the software, Games and the Websites are free from errors.',
      text_7_5:
        'Do not warrant that the software, Games and the Websites will be accessible without interruptions.',
      text_7_6:
        'Shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Websites or your participation in the Games.',
      text_7_7:
        'You understand and acknowledge that, if there is a malfunction in a Game or its interoperability, any bets made during such a malfunction shall be void. Funds obtained from a malfunctioning Game shall be considered void, as well as any subsequent game rounds with said funds, regardless of what Games are played using such funds.',
      text_7_8:
        'You hereby agree to fully indemnify and hold harmless us, our directors, employees, partners, and service providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise in relation to your use of the Website or participation in the Games.',
      text_7_9:
        'To the extent permitted by law, our maximum liability arising out of or in connection with your use of the Websites, regardless of the cause of actions (whether in contract, tort, breach of warranty or otherwise), will not exceed €100.',
      text_8_1: 'If you breach any provision of these User Agreement or we have a reasonable ground to suspect that you have breached them, we reserve the right to not open, to suspend, or to close your Member Account, or withhold payment of your winnings and apply such funds to any damages due by you.',
      text_8_2: 'You acknowledge that disapp.net shall be the final decision-maker of whether you have violated disapp.net’s rules, terms or conditions in a manner that results in your suspension or permanent barring from participation in our site.'
    }
  },
  user_modal: {
    bet_amount: 'Toplam bahis',
    total_games: 'Toplam oyunlar',
    best_multiplier: 'En iyi çarpan',
    best_winning: 'En iyi galibiyet'
  },
  vip_club: {
    title: 'VIP Kulüpleri',
    sub_title: 'Cömert ödüllere ve kişiselleştirilmiş hediyelere özel erişim kazanmak için seviyenizi yükseltin!',
    current_level: 'Mevcut Seviyeniz',
    current: 'Mevcut',
    rakeback: 'Rakeback',
    convert: 'Dönüştür',
    privilege: 'VIP Ayrıcalıkları',
    current_scores: 'Toplanan Puanlar'
  },
  profile: {
    name: 'Hesap ayarları',
    myprofile: 'Kullanıcı bilgisi',
    promo: 'Promosyon kodu',
    promo_input: 'Promosyon',
    helpdesk: 'Yardım merkezi',
    bonus: 'Bonus sistemi',
    active_promo: 'Promosyon kodunu etkinleştirin ve hesabınıza para alın',
    name_user: 'Kullanıcı adı',
    support: 'Müşteri desteği',
    edit_profile: 'Profil düzenleme',
    user_info: 'Kullanıcı bilgisi',
    security: {
      name: 'Güvenlik',
      enter_login: 'Giriş yap',
      you_login: 'Giriş yapmak',
      enter_login_minLength: 'En az 6 karakter',
      new_password: 'Yeni Şifre',
      text_2fa: 'Uygulamadan 6 haneli kodu girin',
      confirm_password: 'Şifreyi Onayla',
      enter_password: 'Parolanı Gir',
      enter_password_minLength: 'En az 6 karakter',
      password_has_uppercase: 'Şifre büyük harf içermelidir',
      password_has_lowercase: 'Şifre küçük harf içermelidir',
      password_has_number: 'Şifre bir sayı içermelidir',
      fa2_auth: 'İki faktörlü kimlik doğrulama',
      password_not_match: 'Parolalar uyuşmuyor',
      current_password: 'Mevcut Şifre',
      protect_account:
        'Hesabınızı iki faktörlü kimlik doğrulama ile koruyun',
      enable_2FA: '2FA etkinleştir',
      disable_2FA: 'Devre dışı bırakmak 2FA',
      button_save: 'Kaydetmek',
      uncorrect_login: 'Yanlış giriş'
    },
    session: {
      name: 'Oturumlar',
      title: 'Oturumlar',
      browser: 'Tarayıcı',
      region: 'Bölge',
      ip: 'IP adresi',
      when: 'Tarih',
      action: 'Eylem',
      back: 'Geri',
      forward: 'Ileri',
      current: 'Akım',
      completed: 'Tamamlanmış'
    },
    fa2: {
      name: 'Iki faktörlü kimlik doğrulama',
      1: 'Uygulamayı indirin',
      2: 'İndir ve yükle',
      3: 'veya',
      4: 'telefonunuz veya tabletiniz için',
      5: 'QR kodunu telefonunuzun kamerasıyla tarayın.',
      6: '2FA TUŞU (MANUEL GİRİŞ)',
      7: 'Şifrenizi girin',
      8: 'Uygulamadan 6 haneli kodu girin',
      9: 'Etkinleştir',
      10: 'Devre dışı bırakmak',
      11: 'Anahtar panoya kopyalandı',
      12: 'QR KODUNU TARAMA'
    },
    verify: {
      name: 'Doğrulama'
    },
    general: {
      title: 'Genel',
      email: 'E-posta',
      min_length: 'En az 3 karakter',
      login: 'Giriş yapmak',
      button_save: 'Kaydetmek',
      button_confirm: 'Onaylamak',
      button_sent: 'Gönderilmiş',
      confirm: 'Onaylanmış',
      data_correctly: 'Tüm verileri doğru bir şekilde doldurun'
    },
    other: {
      name: 'Bağlı hizmetler',
      social: 'Sosyal bağla'
    },
    success: {
      4: 'Başarıyla'
    },
    error: {
      2: 'Lütfen geçerli bir isim girin',
      11: 'Lütfen formu doldurun'
    }
  },
  chat: {
    chat_input: 'Mesajınızı girin...',
    not_auth: 'Sohbeti kullanmak için giriş yapmalısınız.',
    error_load_data: 'Veri yükleme hatası',
    statistic: 'İstatistik',
    favorite_games: 'Favori Oyunlar',
    won: 'Oranlar',
    try_luck:
      'Hash değeri hesaplama ve blok zinciri algoritmasına dayalı bir oyunda, olasılık teorisi ile şansınızı deneyin.',
    air_drop: {
      this_air_drop: 'Sohbetteki kullanıcılar için herhangi bir jetonun kişisel airdrop\'unu başlatabilirsiniz.',
      amount_activate: 'Aktivasyon sayısı',
      sum_air_drop: 'Airdrop miktarı',
      start_air_drop: 'Başlat',
      name: 'Airdrop',
      activated: 'Bonus etkinleştirildi',
      select_currency: 'Para birimini seçin',
      choise_currency: 'Ödeme şekli',
      valid_limit: 'Aktivasyon sayısını girin',
      valid_amount: 'Airdrop miktarını girin',
      start: 'Bir airdrop başlattı, iyi şanslar!',
      give_you: 'Alacaksınız',
      activation: 'Aktivasyonlar',
      activate: 'etkinleştir'
    },
    rules: {
      header: 'Sohbet kuralları',
      1: 'Spam göndermeyin, taciz etmeyin veya diğer kullanıcıları rahatsız etmeyin. Ayrıca, CAPS kullanmaktan kaçının! Kimse kenDisappne bağırılmasından hoşlanmaz!',
      2: 'Kredi, yağmur, bahşiş ve iki katı para için yalvarmayın veya istemeyin.',
      3: 'Potansiyel dolandırıcılık olarak görülebilecek hiçbir şüpheli davranış yok.',
      4: 'Hiçbir şekilde reklam/ticaret/satış/satın alma veya hizmet teklifinde bulunmayın.',
      5: 'URL kısaltıcı kullanmayın. Her zaman orijinal bağlantıyı gönderin.',
      6: 'Belirtilen dil sohbet odalarını uygun şekilde kullanın.'
    }
  },
  menu: {
    tooltip: {
      settings: 'Ayarlar',
      level: 'Boş (0 seviye)',
      more: 'Daha fazla detay',
      exit: 'Çıktı'
    }
  },
  history: {
    title: 'Tarih bahsi',
    my_bets: 'Bahislerim',
    all_bets: 'En son bahisler',
    big_wins: 'Kuvvetli rüzgar',
    cool_moments: 'şanslı kazançlar',
    game: 'Oyun',
    gamer: 'Oyuncu',
    time: 'Zaman',
    bet: 'Bahis',
    coefficient: 'çarpan',
    winnings: 'ödeme'
  }
}
