<template>
  <div class="content-wrapper">
    <DisbetPreloader v-if="getFetchStatus === 'FETCH'" />
    <slot v-else></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { DisbetPreloader } from '@/common/components'

export default {
  name: 'DepositWrapper',
  components: { DisbetPreloader },
  computed: {
    ...mapGetters('cash/deposit', ['getErrorStatus', 'getFetchStatus'])
  }
}
</script>

<style>
.content-wrapper {
  width: 100%;
}
</style>
