export default {
  namespaced: true,
  state: {
    pages: [
      {
        name: 'navigations.main',
        to: '/main',
        need_auth: false
      },
      {
        name: 'navigations.casino',
        to: '/games/casino/main',
        need_auth: false
      },
      {
        name: 'navigations.livegames',
        to: '/games/live/main',
        need_auth: false
      }
    ],
    conditionOfUsage: false
  },
  getters: {
    pages (state) {
      return state.pages
    },
    conditionOfUsage (state) {
      return state.conditionOfUsage
    }
  },
  mutations: {
    setConditionOfUsage (state) {
      state.conditionOfUsage = !state.conditionOfUsage
    }
  },
  actions: {
    setConditionOfUsage (store) {
      store.commit('setConditionOfUsage')
    }
  }
}
