<template>
  <DisbetModal class="content">
    <ModalHeader/>
    <button @click="$emit('close')" class="dm-close-icon">
      <i style="font-size: 1.15rem;" class="pi pi-times"></i>
    </button>
    <ModalBody/>
  </DisbetModal>
</template>
<script>
import { DisbetModal } from '@/common/components'

import ModalBody from './Body.vue'
import ModalHeader from './Header.vue'

export default {
  components: { ModalHeader, ModalBody, DisbetModal },
  props: ['currentUser'],
  methods: {},
  created () {}
}
</script>
<style scoped>
.dm-close-icon {
  position: absolute;
  font-weight: bold;
  right: 10px;
  top: 17px;
  z-index: 11;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: transparent;
  color: #969696;
}

.content {
  padding: 1rem;
  background-image: radial-gradient(circle at 50% -20%, rgb(101 64 211), rgb(30, 32, 36) 70%);
}

.pi-times {
  color: white;
}

@media (max-width: 576px) {
  .dm-wrapper {
    height: 40vh;
  }
}
</style>
