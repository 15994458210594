import { apiTransport } from './transport'

class BonusApiService {
  constructor (transport) {
    this.transport = transport
  }

  getBonusLogs () {
    return this.transport.get(`/api/player/bonus/activation-history`)
  }
}

export const bonusApiService = new BonusApiService(apiTransport)
