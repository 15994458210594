<template>
  <div>
    <DisbetCurrencyChooserList
      v-if="page === 'PAGE_CURRENCY_CHOOSER'"
      :useCurrencyListAccounts="true"
      :currencyList="currencyList"
      :onChooseCurrency="onChooseCurrency"
      :onSetPage="setPage"
    />
    <DisbetCurrencyChooserActiveCurrency v-else :onSetPage="setPage"/>
  </div>
</template>
<script>
import DisbetCurrencyChooserList from './CurrencyChooserList'
import DisbetCurrencyChooserActiveCurrency from './CurrencyChooserActiveCurrency'

export default {
  components: { DisbetCurrencyChooserActiveCurrency, DisbetCurrencyChooserList },
  props: ['currencyList', 'onChooseCurrency'],
  name: 'DisbetCurrencyChooser',
  data () {
    return {
      page: 'PAGE_CURRENCY_CHOOSER'
    }
  },
  methods: {
    setPage (page) {
      this.page = page
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
