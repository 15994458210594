export const DELAY_MESSAGE = 5000
export const MESSAGE_TYPE = {
  COMMON: 'common',
  GIF: 'gif',
  SYSTEM: 'system',
  TRANSFER: 'transfer',
  AIRDROP: 'airdrop',
  ADMIN: 'admin',
  MODERATOR: 'moderator',
  GAME_LOG: 'game_log'
}
