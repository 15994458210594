<template>
  <div>
    <Fieldset :legend="$t('helpdesk.header')">
      <div class="d-md-flex justify-content-center">
        <div class="support-chat col d-flex">
          <div class="support-icon">
            <i class="fas fa-headset"></i>
          </div>
          <div class="d-flex align-items-center pl-3">
            {{ $t('helpdesk.support_live') }} <br>
            <a @click="openChatra()" class="support-href">{{ $t('helpdesk.open_chat') }}</a> <br>
            <span class="text-grey">{{ $t('helpdesk.time_info_min') }}</span>
          </div>
        </div>
        <div class="support-chat col d-flex">
          <div class="support-icon">
            <i class="fas fa-envelope"></i>
          </div>
          <div class="d-flex align-items-center pl-3">
            {{ $t('helpdesk.support_email') }} <br>
            <a class="support-href" target="_blank" href="mailto:support@disapp.net">support@disapp.net</a> <br>
            <span class="text-grey">{{ $t('helpdesk.time_info_max') }}</span>
          </div>
        </div>
      </div>
    </Fieldset>
    <Accordion lazy>
      <AccordionTab :header="$t('helpdesk.faq.name[1]')">
        {{ $t('helpdesk.faq.text[1]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[2]')">
        {{ $t('helpdesk.faq.text[2]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[3]')">
        {{ $t('helpdesk.faq.text[3]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[4]')">
        {{ $t('helpdesk.faq.text[4]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[5]')">
        {{ $t('helpdesk.faq.text[5]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[6]')">
        {{ $t('helpdesk.faq.text[6]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[7]')">
        {{ $t('helpdesk.faq.text[7]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[8]')">
        {{ $t('helpdesk.faq.text[8]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[9]')">
        {{ $t('helpdesk.faq.text[9]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[10]')">
        {{ $t('helpdesk.faq.text[10]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[11]')">
        {{ $t('helpdesk.faq.text[11]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[12]')">
        {{ $t('helpdesk.faq.text[12]') }}
      </AccordionTab>
      <AccordionTab :header="$t('helpdesk.faq.name[13]')">
        {{ $t('helpdesk.faq.text[13]') }}
      </AccordionTab>
    </Accordion>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
    }
  },
  name: 'Faq',
  methods: {
    ...mapActions('app', ['openChatra'])
  }
}
</script>
<style lang="scss" scoped>
.support {
  &-chat {
    border: 1px solid #3e4053;
    margin-left: 15px;
    padding: 10px;
    @media (max-width: 767px) {
      margin-left: 0px;
      margin-top: 15px;
    }
  }

  &-icon {
    height: 75px;
    font-size: 32px;
    align-items: center;
    border-right: 1px solid #333a48;
    width: 70px;
    display: flex;
    justify-content: center;
    @media (max-width: 1270px) {
      display: none;
    }
    @media (max-width: 767px) {
      display: flex;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }

  &-href {
    color: #7f64e3;
    cursor: pointer;
  }

  &-href:hover {
    color: #846ed5;
  }
}
</style>
