<template>
  <DisbetModal class="content">
    <ModalHeader/>
    <button @click="$emit('close')" class="dm-close-icon">
      <i style="font-size: 1.15rem;" class="pi pi-times"></i>
    </button>
    <div class="p-3 row justify-content-center text-white font-weight-bold">
      <span>{{ bonus.payment.amount }} {{ bonus.payment.currencyId }} </span>
      <img :src="bonus.payment.method.currency.src">
      <i class="fa-solid fa-plus p-1 ml-2 mr-2"></i>
      <span>{{ bonus.bonus.reward }} DAC <img style="margin-top: -5px;" src="https://static.disapp.net/avatars/w_1Zg4VaqNkaQdSRwx4v7b5o.blob"></span>
    </div>
    <div class="text-center text-white">{{ $t('cashbox.bonus.good_game') }} </div>
    <ModalBody :bonus="bonus"/>
  </DisbetModal>
</template>
<script>
import { DisbetModal } from '@/common/components'

import ModalBody from './Body.vue'
import ModalHeader from './Header.vue'

export default {
  components: { ModalHeader, ModalBody, DisbetModal },
  props: ['bonus'],
  name: 'ModalUserInfo',
  methods: {},
  created () {}
}
</script>
<style scoped>
.dm-close-icon {
  position: absolute;
  font-weight: bold;
  right: 10px;
  top: 17px;
  z-index: 11;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: transparent;
  color: #969696;
}

.content {
  padding: 1rem;
  background-image: radial-gradient(circle at 50% -20%, rgb(101 64 211), rgb(30, 32, 36) 70%);
}

img {
  height: 25px;
  margin-left: 5px;
}

@media (max-width: 576px) {
  .dm-wrapper {
    height: 40vh;
  }
}
</style>
