<template>
  <DisbetModal class="content">
        <div v-if="active === 1" class="title">
          <div class="text-white" style="font-weight: bold; font-size: 1.14rem; margin-left: 21px;">
            <button
              @click="active = 2"
              class="dm-back-icon"
            >
              <i style="cursor: pointer; font-size: 1.25rem; margin-left: 0;" class="pi pi-chevron-left"></i>
            </button>
            DAC
          </div>
        </div>
        <div v-if="active === 2" class="title">
          <div class="text-white" style="font-weight: bold; font-size: 1.14rem">
            {{ $t('cash.dac.panel')}}
          </div>
        </div>
      <button @click="$emit('close')" class="dm-close-icon">
        <i style="font-size: 1.15rem;" class="pi pi-times"></i>
      </button>
      <transition name="component-fade" mode="out-in">
        <DBD @openDashboard="active = 2" v-if="active === 1"/>
        <Dashboard @openDBD="active = 1" v-if="active === 2"/>
      </transition>
    </DisbetModal>
</template>

<script>
import DBD from '../components/DisappDollar/DAC'
import Dashboard from '../components/DisappDollar/Dashboard'
import { DisbetModal } from '@/common/components'

export default {
  name: 'DisbetDollarModal',
  components: {
    DBD,
    Dashboard,
    DisbetModal
  },
  data: () => ({
    active: 2
  })
}
</script>

<style scoped lang="scss">
.dm-back-icon {
  position: absolute;
  top: 20px;
  left: 10px;
  background: transparent;
  color: #969696;
}
.title {
  display: flex;
  justify-content: space-between;
  margin: 0.12rem 3.57rem 1.1rem 0.71rem;
  @media (max-width: 390px) {
    margin: 0.12rem 2.57rem 0 0.71rem;
  }
}
.title-right {
  font-weight: 300;
  cursor: pointer;
  margin-top: 2px;
}
.text-white {
  font-weight: bold;
  font-size: 1.14rem;
}
.dm-close-icon {
  position: absolute;
  font-weight: bold;
  right: 10px;
  top: 17px;
  z-index: 11;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: transparent;
  color: #969696;
}

.content {
  padding: 1rem;
  background-image: radial-gradient(circle at 50% -20%, rgb(101 64 211), rgb(30, 32, 36) 70%);
  overflow-y: scroll;
  width: 500px;
}
.content::-webkit-scrollbar {
  width: 0;
}
.component-fade-enter-active {
  animation-name: fadeInLeft;
  animation-duration: 0.3s;
}

.component-fade-leave-active {
  animation-name: fadeOutLeft;
  animation-duration: 0.3s;
}

.back-to-dbd {
  color: #90929f;
  cursor: pointer;
}
</style>
