<template>
  <div class="promo-fluid row d-flex flex-column main-wrapper" v-if="init">
    <div v-if="!auth">
      <div class="mb-2 mt-3 text-white text-uppercase promo-title">{{$t(`promo.title`)}}</div>
      <div class="mb-2 text-white promo-cash">20 000,00$</div>
      <Button v-show="!auth" @click="$router.push({ query: { modal: 'auth' } })" class="button-submit">
        {{ $t('authentication.register_title') }}
      </Button>
      <img class="promo-icon d-none d-md-block" alt="promo" src=@/assets/images/banner/promo.png>
    </div>
    <div v-else class="welcome-container">
      <div class="mb-2 mt-3 text-white text-uppercase promo-title">{{$t(`promo.welcomeText`, { nickName: nick })}}</div>
      <ProgressBar :show-value="false" :value="progressPercent"></ProgressBar>
      <div class="d-flex justify-content-between">
        <div class="mb-2 mt-3 text-white">{{$t(`promo.currentLevel`, { currentLevel: currentLevel })}}</div>
        <div class="mb-2 mt-3 text-white">{{$t(`promo.toNextLevel`, { exp: userLevel.exp, level: nextLevel })}}</div>
      </div>
      <img class="promo-icon" alt="promo" src=@/assets/images/banner/promo.png>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  watch: {},
  methods: {},
  computed: {
    ...mapGetters('app', {
      init: 'getInit', isLang: 'getLang'
    }),
    ...mapGetters('user', ['auth', 'userLevel', 'nick']),
    currentLevel () {
      return this.userLevel.level
    },
    nextLevel () {
      return this.userLevel.level + 1
    },
    progressPercent () {
      return Math.floor((this.userLevel.nextLevelExp / this.userLevel.exp) / 100)
    }
  }
}
</script>

<style scoped lang="scss">
  .p-progressbar {
    border-radius: 50px;
  }
  .welcome-container {
    max-width: 60%;
    align-content: center;
    min-height: 200px;

    @media (max-width: 991px) {
      max-width: 100%;
      min-height: auto;
    }
  }
  .main-wrapper {
    position: relative;
  }
  .promo {
    &-fluid {
      padding: 25px
    }
    &-title {
      font-size: 20px;
      max-width: 360px;
    }

    &-cash {
      font-size: 64px;
      background: linear-gradient(0deg, #8348FF 0%, #9d77f8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      @media (max-width: 480px) {
        font-size: 48px;
      }
    }

    &-icon {
      position: absolute;
      top: 25px;
      right: 0;
      width: 32%;
      height: auto;
      min-width: 400px;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
</style>
