<template>
  <div class="card-promocode card h-100">
    <div class="card-body d-flex flex-column">
      <p class="p-text-bold text-wrap card-body__title">Активировать <br> промокод</p>
      <InputText class="mt-2 w-100 p-form-control mt-3" type="text" placeholder="Введите код" v-model="promoCodeValue"/>
      <Button class="p-button p-component card-button">Получить</Button>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    promoCodeValue: ''
  })
}
</script>

<style lang="scss" scoped>
.card {
  &-promocode {
    border: none;
    color: white;
    min-height: 260px;
    padding: 25px 25px;
    border-radius: 25px;
    overflow: hidden;
    background: rgb(142,67,154);
    background: radial-gradient(circle, rgba(142,67,154,1) 0%, rgba(46,18,125,1) 100%);

    @media (min-width: 420px) {
      padding: 25px 25px;
    }
  }

  .p-form-control {
    box-shadow: -3px 2px 8.6px 0px #00000040;
    position: relative;
    color: white;
    background-color: rgba(14, 10, 65, 0.51);
    border-radius: 12px;
    padding-right: 40px;
    height: 40px;
    font-size: 14px;
  }
  &-button {
    border-radius: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  &-body {
    &__title {
      font-size: 22px;
      margin: 0;
      line-height: 33px;

      @media (max-width: 767px) {
        font-size: 22px;
      }

      @media (max-width: 576px) {
        font-size: 19px;
      }
    }
  }
}
</style>
