<template>
  <div class="row" v-if="init">
    <div class="swiper swiper0">
      <div class="swiper-wrapper cursor-pointer">
        <div class="swiper-slide" :key="idx" v-for="(banner, idx) in banners">
          <router-link tag="div" :to="banner.link">
              <img class="banner" :src="banner.img">
              <div class="banner-container">
                <div class="banner-block">
                  <div class="banner-header">{{ banner.header }}</div>
                  <div class="banner-text">{{ banner.text }}</div>
                </div>
              </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Swiper from 'swiper'

export default {
  data: () => ({
    banners: []
  }),
  watch: {
    isLang (val) {
      this.banners = this.getBanners(val.code)
    }
  },
  methods: {
    getBanners (code) {
      return [
        {
          img: require(`@/assets/images/banner/banner-1.png`),
          link: '/',
          text: 'Зарабатывай уровни и получай награды',
          header: 'VIP клуб'
        },
        {
          img: require(`@/assets/images/banner/banner-2.png`),
          link: '/',
          text: 'Получи +180% к первому пополнению',
          header: 'Выгодный депозит'
        },
        {
          img: require(`@/assets/images/banner/banner-3.png`),
          link: '/',
          text: 'Любой криптовалютой и фиатом',
          header: 'Пополняй и выводи'
        },
        {
          img: require(`@/assets/images/banner/banner-4.png`),
          link: '/',
          text: 'Забирай кэшбек, каждую неделю',
          header: 'Кэшбек'
        },
        {
          img: require(`@/assets/images/banner/banner-5.png`),
          link: '/',
          text: 'Бесплатное вращение, каждый день',
          header: 'Крути колесо'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', {
      init: 'getInit', isLang: 'getLang'
    })
  },
  beforeMount () {
    this.banners = this.getBanners(this.isLang.code)
  },
  mounted () {
    // eslint-disable-next-line no-new
    new Swiper('.swiper0', {
      speed: 2000,
      slidesPerView: 1,
      spaceBetween: 5,
      breakpoints: {
        768: { slidesPerView: 3 }
      },
      direction: 'horizontal',
      loop: true,
      rewind: false,
      autoplay: {
        delay: 4500,
        waitForTransition: true
      },
      roundLengths: true
    })
  }
}
</script>
<style lang="scss">
.banner {
  width: 100%;;
  padding: 10px;
  border-radius: 15px;
  &-container {
    position: absolute;
    bottom: 0;
    left: 15px;
    color: white;
    padding: 5px;
    border-radius: 15px;
    width: 58%;
    height: 100%;
    align-items: center;
    display: flex;
  }
  &-header {
    font-size: 28px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
    @media (max-width: 1300px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &-text {
    font-size: 14px;
    text-transform: uppercase;
    @media (max-width: 1200px) {
      font-size: 10px;
    }
  }
}
</style>
