import { apiTransport } from './transport'

class ChatApiService {
  constructor (transport) {
    this.transport = transport
  }
  // todo remove this to bonus service
  createBonus (limit, getAirDropCurrencyId, amount) {
    return this.transport.post(`/api/player/bonus/createBonus`, { limit: limit, currencyId: getAirDropCurrencyId, amount: amount, type: 'airdrop' })
  }
  activateBonus ({ id, code }) {
    return this.transport.post(`/api/player/bonus/activateBonus`, { id, code })
  }

  sendMessage (data) {
    return this.transport.post(`/api/player/chat/sendMessage`, data)
  }

  chatBanUser (data) {
    return this.transport.post(`/api/player/chat/banUser`, data)
  }

  chatDeleteMessage (data) {
    return this.transport.post(`/api/player/chat/deleteMessage`, data)
  }

  loadChat ({ channelId }) {
    return this.transport.post(`/api/player/chat/load`, { channelId })
  }

  loadChannels () {
    return this.transport.post(`/api/player/chat/loadChannels`)
  }
}

export const chatApiService = new ChatApiService(apiTransport)
