<template>
  <div class="footer--wrapper mt-3">
    <div class="row footer p-4">
      <a :href="host" class="footer-pwa">
        {{ $t('footer.install_disbet_app') }}
        <div class="os">
          <i class="fab fa-apple"></i>
          <i class="pi pi-android"></i>
        </div>
      </a>
      <div class="footer-logo">
        <Logo class="size"></Logo>
      </div>
      <div class="footer-desc">
        <p class="mt-3">Disapp is a community-based crypto casino that offers their players the best online casino experience possible!
          At disapp.net, we are convinced that blockchain will change the online crypto gambling industry forever!
          Jump in and check it out for yourself!
          Have fun and earn crypto</p>
      </div>
      <div class="footer-line-x"></div>
      <div class="footer-header">
        <div :key="idx" v-for="(fomenu, idx) in menu" class="footer-text mt-2">
          <router-link tag="li" class="path-name" :to="fomenu.link">{{ $t(fomenu.name) }}</router-link>
        </div>
      </div>
      <div class="footer-support">
        <div class="lang">
          <DisbetLang />
        </div>
        <Button @click="openChatra()" class="button-support mb-3"><i class="fas fa-life-ring"></i> {{ $t('footer.support') }} </Button>
        <span class="footer-copyright">© 2023 disapp.net ALL RIGHTS RESERVED</span>
      </div>
      <hr class="footer-line">
      <div class="footer-social justify-content-center row">
        <div class="col-12 col-md-6 footer-link text-left">
          <a href="https://t.me/Disappcash"><i class="footer-icon fab fa-telegram-plane"></i></a>
          <a href="https://twitter.com/DisappcashOfficial"><i class="footer-icon fab fa-twitter"></i></a>
          <a href="https://discord.gg/xXxD7xBTNe"><i class="footer-icon fab fa-discord"></i></a>
        </div>
        <div class="footer-currency">
          <p>1 BTC = {{ getCurrencyCourseByCode('BTC') }}$</p>
        </div>
      </div>
      <hr class="footer-line">
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Logo from '@/assets/svg/logo-full.svg'
import { DisbetLang } from '@/common/components/Lang'
export default {
  components: {
    Logo,
    DisbetLang
  },
  data () {
    return {
      menu: [
        {
          name: 'footer.term_of_use',
          link: '/helpdesk/terms'
        },
        {
          name: 'footer.privacy_policy',
          link: '/helpdesk/policy'
        },
        {
          name: 'footer.helpdesk',
          link: '/helpdesk/faq'
        }
      ]
    }
  },
  name: 'Footer',
  methods: {
    ...mapActions('app', ['openChatra'])
  },
  computed: {
    ...mapGetters('user', ['currentAccount']),
    ...mapGetters('currency', ['getCurrencyCourseByCode']),
    host () {
      return window.location.origin
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-wrap: wrap;
  max-width: 1320px;
  margin: 0 auto;
  &-wrapper {
    background-color: rgb(28 31 41 / 68%);
  }
  &-pwa {
    background-color: #303441;
    width: 100%;
    color: white;
    border-radius: 15px;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 16px;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;
    align-items: center;

    .os {
      margin-left: auto;
    }
  }

  &-support {
    width: 25%;
    flex-direction: column;
    display: flex;
    font-size: 14px;
    margin-left: auto;
    line-height: 14px;
    .lang {
      margin: 0 auto 10px auto;
    }
  }

  &-header {
    width: 26%;
    flex-direction: column;
    display: flex;
  }

  &-desc {
    width: 25%;
    flex-direction: column;
    display: flex;
    font-size: 12px;
  }
  &-logo {
    width: 100%;
    .size {
      width: 150px;
    }
  }

  &-line-x {
    border-right: 2px solid rgb(46 51 64 / 27%);
    margin-right: 50px;
    margin-left: 50px;
  }

  &-social {
    width: 100%;
    flex-direction: column;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: white;
    margin-top: 25px;
  }

  &-text {
    display: inline-block;
    font-size: 13px;
    line-height: 10px;
    color: #fff;
    font-weight: 300;
    white-space: pre;
    margin-bottom: 10px;
  }

  &-copyright {
    font-size: 9px;
    width: 100%;
    text-align: center;
    line-height: 12px;
    margin-top: 5px;
    font-weight: 300;
    color: #fff;
  }

  &-link {
    font-size: 14px;
    line-height: 10px;
    color: white;
    font-weight: 300;
    white-space: pre;
    margin-bottom: 10px;
  }

  &-icon {
    background-color: #1c1f29;
    padding: 9px;
    margin-right: 7px;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
  }

  &-line {
    width: 100%;
    background-color: #2e3340;
    height: 1px;
    opacity: 0.6;
    margin-bottom: 20px;
  }
  &-currency {
    text-align: center;
    font-size: 14px;
    line-height: 10px;
    color: white;
    font-weight: 300;
    white-space: pre;
    margin-bottom: 10px;
    margin-top: 15px;
  }
}

.path-name:hover {
  cursor: pointer;
  color: #c3c3cc;
}

@media (max-width: 1346px) {
  .footer-header {
    width: 30%;
    margin-bottom: 35px;
  }
  .footer-support {
    width: 40%;
  }
}

@media (max-width: 1600px) {
  .footer-line {
    margin-bottom: 10px;
  }
  .footer-logo {
    text-align: center;
  }
  .footer-line-x {
    display: none;
  }
  .footer-desc {
    width: 100%;
  }
  .footer-header {
    display: none
  }
  .footer-support {
    width: 100%;
    margin-bottom: 35px;
    text-align: center;
  }
  .button-support {
    margin: auto;
  }
  .footer {
    margin-bottom: 50px;
  }
  .footer-social {
    margin: auto;
    text-align: center;
    width: fit-content;
  }
  .footer-icon {
    margin-right: 5px;
  }
  .footer-link {
    line-height: 30px;
  }
  .footer-pwa {
    font-weight: 300;
    margin-bottom: 25px;
    padding: 9px 16px 16px 13px;
    max-height: 42px !important;
  }
}

@media (min-width: 991px) {
  .footer-pwa {
    display: none;
  }
}
@media (min-width: 1600px) {
  .footer-currency {
    text-align: right;
    margin-left: auto;
  }
}

@media (max-width: 468px) {
  .footer-support {
    font-size: 14px;
  }
  .footer-text {
    font-size: 10px;
  }
  .footer-link {
    font-size: 12px;
  }
  .footer-icon {
    font-size: 12px;
  }
}

.fa-apple {
  font-size: 24px;
  margin-right: 15px;
}

.pi-android {
  font-size: 22px;
  color: #86f24e;
}

@media (max-width: 394px) {
  .fa-apple {
    font-size: 20px;
    margin-right: 15px;
  }
  .pi-android {
    font-size: 18px;
    color: #86f24e;
  }
  .footer-support {
    font-size: 13px;
  }
  .footer-text {
    font-size: 9px;
  }
}
.fa-life-ring {
  font-size: 26px;
  padding-right: 15px;
}

.button-support {
  height: 40px;
  border-radius: 5px;
  max-width: 280px;
}

</style>
