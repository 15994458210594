import SoftSwiss from '@/games/SoftSwiss/Index'
import SoftSwissMain from '@/games/SoftSwiss/Main'
import SoftSwissGamesList from '@/games/SoftSwiss/GamesList'

export const liveGamesRouter = [
  {
    name: 'Disapp - Live',
    path: '/games/live',
    redirect: '/games/live/main',
    meta: { type: 'live' },
    component: SoftSwiss,
    children: [
      {
        name: 'Disapp - Game',
        path: 'main',
        meta: { type: 'live' },
        component: SoftSwissMain
      },
      {
        name: 'Disapp - Game',
        path: ':provider',
        meta: { type: 'live' },
        component: SoftSwissGamesList
      }
    ]
  }
]
