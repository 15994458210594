<template>
    <div>
      <div class="row mt-3">
        <div class="col-12 casino-header col-md-6">
          <a @click="$router.go(-1)">
            <i class="pi pi-chevron-left"></i>
          </a>
          <div class="ml-3">
            <span v-if="$route.params.type === 'casino'">{{ $t('navigations.casino') }}</span>
            <span v-if="$route.params.type === 'live'">{{ $t('navigations.livegames') }}</span>
          </div>
        </div>
        <Menu class="col-12 col-lg-6" />
      </div>
      <SliderCarousel :lite="true" :list="getListByName($route.params.type === 'casino' ? 'FBIS' : 'RLG')"
                      :rows="1"
      />
      <SliderCarousel :lite="true" :list="getListByName($route.params.type === 'casino' ? 'TCG' : 'TLG')"
                      :rows="1"
      />
      <SliderCarousel :lite="true" :list="getListByName($route.params.type === 'casino' ? 'HCG' : 'HLG')"
                      :rows="1"
      />
      <SliderCarousel :lite="true" :list="getListByName($route.params.type === 'live' ? 'bfc' : 'blg')"
                      :rows="1"
      />
      <GamesList/>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SliderCarousel from '@/common/components/SliderCarousel/SliderCarousel'
import GamesList from '@/common/components/GamesList/GamesList'
import Menu from './Menu'

export default {
  name: 'Main',
  components: {
    SliderCarousel,
    GamesList,
    Menu
  },
  methods: {
    ...mapMutations('game', ['setSelectedProducer', 'setSelectedCategory'])
  },
  computed: {
    ...mapGetters('game', ['getListByName'])
  },
  mounted () {
    this.setSelectedProducer({})
    this.setSelectedCategory({})
  }
}
</script>

<style lang="scss" scoped>
.casino-header {
  display: flex;
  font-weight: 700;
  font-size: 24px;
  align-items: center;
  color: white;
}
.pi-chevron-left {
  color: #90929f;
  &:hover {
     cursor: pointer;
   }
}
</style>
